import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { PROD_API_URL } from '../../const/constants'
import { Observable } from 'rxjs'
import { IComparativoMensual } from '../../interfaces/comparativoMensual'

const api_address = 'api/Reports/ComparativoMensual'

@Injectable({
    providedIn: 'root',
})
export class ComparisonService {
    constructor(private httpClient: HttpClient) {}

    get(startDate: string, endDate: string): Observable<IComparativoMensual> {
        return this.httpClient.get<IComparativoMensual>(
            `${PROD_API_URL}${api_address}?startDate=${startDate}&endDate=${endDate}`
        )
    }
}
