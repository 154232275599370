import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { CookieService } from 'ngx-cookie-service'
import { CONFIGURATION_ITEMS, COOKIE_ROLE } from 'src/app/core/const/constants'
import { Roles } from 'src/app/core/enums/roles'
import { ConfigurationService } from 'src/app/core/services/api/configuration.service'
import { SideNavService } from 'src/app/core/services/util/side-nav.service'

@Component({
    selector: 'app-side-nav',
    templateUrl: './side-nav.component.html',
    styleUrls: ['./side-nav.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class SideNavComponent implements OnInit {
    private _isAdmin = false
    private _isCalendarEnabled = false
    get isAdmin() {
        return this._isAdmin
    }
    get isCalendarEnabled() {
        return this._isCalendarEnabled
    }
    constructor(
        public sideNavService: SideNavService,
        public router: Router,
        private configurationService :ConfigurationService,
        private cookieService: CookieService
    ) {}

    ngOnInit(): void {
        let role = this.cookieService.get(COOKIE_ROLE)
        this._isAdmin = role === Roles.Administrator
        const ffCalendar =this.configurationService.retrieveByName(CONFIGURATION_ITEMS.FFCalendar)
        this._isCalendarEnabled = ffCalendar?.value == 'true'
    }
    navigateTo(route: string) {
        this.router.navigate([route])
    }
}
