import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'
import { Observable, Subject } from 'rxjs'
import { PROD_API_URL } from '../../const/constants'
import { IRecord, IRecordWithEvents } from '../../interfaces/record'
import { IRecordService } from '../../interfaces/api/record-service'
import { IEvent } from '../../interfaces/event'

const API_URL = 'api/record'
@Injectable({
    providedIn: 'root',
})
export class RecordsService implements IRecordService {
    private _income = new Subject<IRecord[]>()
    get income() {
        return this._income.asObservable()
    }

    private _expenses = new Subject<IRecord[]>()
    get expenses() {
        return this._expenses.asObservable()
    }
    private _records: IRecord[] = []
    constructor(private httpService: HttpClient) {
        this.updateData()
    }

    get(): Observable<IRecord[]> {
        return this.httpService.get<IRecord[]>(`${PROD_API_URL}${API_URL}`)
    }

    getById(id: number): Observable<IRecord> {
        return this.httpService.get<IRecord>(`${PROD_API_URL}${API_URL}/${id}`)
    }
    post(body: UntypedFormGroup): Observable<IRecord> {
        return this.httpService.post<IRecord>(`${PROD_API_URL}${API_URL}`, body)
    }

    put(body: UntypedFormGroup, id: number): Observable<IRecord> {
        return this.httpService.put<IRecord>(
            `${PROD_API_URL}${API_URL}/${id}`,
            body
        )
    }
    delete(id: number): Observable<IRecord> {
        return this.httpService.delete<IRecord>(
            `${PROD_API_URL}${API_URL}/${id}`
        )
    }
    eventsByRange(dateStart: string, dateEnd: string): Observable<IRecord[]> {
        return this.httpService.get<IRecord[]>(
            `${PROD_API_URL}${API_URL}/EventsByRange?dateStart=${dateStart}&dateEnd=${dateEnd}`
        )
    }
    eventsByRecord(recordId: number): Observable<IRecordWithEvents> {
        return this.httpService.get<IRecordWithEvents>(
            `${PROD_API_URL}${API_URL}/events?id=${recordId}`
        )
    }
    filterRecords() {
        this._income.next(
            this._records.filter((record) => record.recordTypeId === 1)
        )
        this._expenses.next(
            this._records.filter((record) => record.recordTypeId === 2)
        )
    }

    updateData() {
        this.get().subscribe((records) => {
            this._records = records
            this.filterRecords()
        })
    }
}
