<h2 class="media-only">{{ dateString }}</h2>
<mat-card class="no-media">
    <div class="form form-top">
        <h2>Filtros</h2>
        <div class="filtros">
            <mat-form-field>
                <mat-label>Rango de fechas</mat-label>
                <mat-date-range-input
                    [formGroup]="range"
                    [max]="maxDate"
                    [rangePicker]="picker"
                >
                    <input
                        matStartDate
                        formControlName="start"
                        placeholder="Fecha Inicial"
                    />
                    <input
                        matEndDate
                        formControlName="end"
                        placeholder="Fecha Final"
                        (dateChange)="setRange()"
                    />
                </mat-date-range-input>
                <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
                <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
                <!--<mat-error *ngIf="range.controls['start']hasError('matStartDateInvalid')">Invalid start date</mat-error>
                <mat-error *ngIf="range.controls['end']hasError('matEndDateInvalid')">Invalid end date</mat-error>-->
            </mat-form-field>
            <mat-form-field color="accent" class="title">
                <input
                    matInput
                    class="form-control"
                    placeholder="Titulo del Reporte"
                    [(ngModel)]="dateString"
                />
            </mat-form-field>
        </div>
        <button class="print_button" mat-raised-button (click)="print()">
            <mat-icon>print</mat-icon>Imprimir
        </button>
    </div>
</mat-card>
<div class="row tables">
    <div class="col-md-6">
        <h2>Ingresos</h2>
        <div
            *ngFor="let tables of assetsDataSources"
            class="container table3 mat-elevation-z8 page"
        >
            <h4>{{ tables.category }}</h4>
            <table mat-table [dataSource]="tables.subcategory">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Subcategoria</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.Key }}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="count">
                    <th class="currency" mat-header-cell *matHeaderCellDef>
                        Cantidad
                    </th>
                    <td class="currency" mat-cell *matCellDef="let element">
                        {{ element.Count }}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>Total:</td>
                </ng-container>

                <!-- Weight Column -->
                <ng-container matColumnDef="total">
                    <th class="currency" mat-header-cell *matHeaderCellDef>
                        Total
                    </th>
                    <td class="currency" mat-cell *matCellDef="let element">
                        <label
                            ><span>$</span
                            >{{ element.Total | currency: '':'' }}</label
                        >
                    </td>
                    <td class="currency" mat-footer-cell *matFooterCellDef>
                        <label
                            ><span>$</span
                            >{{ tables.Total | currency: '':'' }}</label
                        >
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                ></tr>
                <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
            </table>
        </div>
    </div>
    <div class="col-md-6">
        <h2>Egresos</h2>
        <div
            *ngFor="let tables of outcomeDataSources"
            class="container table3 mat-elevation-z8 page"
        >
            <h4>{{ tables.category }}</h4>
            <table mat-table [dataSource]="tables.subcategory">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Subcategoria</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.Key }}
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>

                <!-- Quantity Column -->
                <ng-container matColumnDef="count">
                    <th class="currency" mat-header-cell *matHeaderCellDef>
                        Cantidad
                    </th>
                    <td class="currency" mat-cell *matCellDef="let element">
                        {{ element.Count }}
                    </td>
                    <td mat-footer-cell *matFooterCellDef>Total:</td>
                </ng-container>

                <!-- Total Column -->
                <ng-container matColumnDef="total">
                    <th class="currency" mat-header-cell *matHeaderCellDef>
                        Total
                    </th>
                    <td class="currency" mat-cell *matCellDef="let element">
                        <label
                            ><span>$</span
                            >{{ element.Total | currency: '':'' }}</label
                        >
                    </td>
                    <td class="currency" mat-footer-cell *matFooterCellDef>
                        <label
                            ><span>$</span
                            >{{ tables.Total | currency: '':'' }}</label
                        >
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                ></tr>
                <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
            </table>
        </div>
    </div>

    <div class="row total">
        <div class="col-md-6">
            <table>
                <tr>
                    <th>Saldo anterior:</th>
                    <td>
                        <label
                            ><span>$</span
                            >{{
                                previous_balance | currency: '':''
                            }}</label
                        >
                    </td>
                </tr>
                <tr>
                    <th>Ingresos:</th>
                    <td>
                        <label
                            ><span>$</span
                            >{{ total_income | currency: '':'' }}</label
                        >
                    </td>
                </tr>
                <tr>
                    <th>Suma:</th>
                    <td>
                        <label
                            ><span>$</span
                            >{{
                                total_income + previous_balance
                                    | currency: '':''
                            }}</label
                        >
                    </td>
                </tr>
            </table>
            <!--<mat-card class="total">
                <h5>Total ingresos:</h5>
                <label><span>$</span><label>{{ total_income | currency : '':'' }}</label></label>
            </mat-card>-->
        </div>
        <div class="col-md-6">
            <table>
                <tr>
                    <th></th>
                    <td></td>
                </tr>
                <tr>
                    <th>Egresos:</th>
                    <td>
                        <label
                            ><span>$</span
                            >{{ total_outcome | currency: '':'' }}</label
                        >
                    </td>
                </tr>
                <tr>
                    <th>Saldo final:</th>
                    <td>
                        <label
                            ><span>$</span
                            >{{
                                total_income +
                                    previous_balance -
                                    total_outcome | currency: '':''
                            }}</label
                        >
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>
