<header>
    <img src="./assets/img/logo_simple.png" />
    <h3>e-cclesia</h3>
    <button class="print_button" mat-raised-button (click)="print()">
        <mat-icon>print</mat-icon>Imprimir
    </button>
</header>
<div
    class="print-content"
    [ngClass]="{
        height100: record.events.length === 0,
        'print-content-no-dups':
            showOutcomeDuplicate === false && record.recordTypeId != 1
    }"
>
    <div class="container ticket">
        <div class="title-icon" *ngIf="icon.length > 0">
            <img src="./assets/img/temp/{{ icon }}" />
            <div>
                <h2>{{ name }}</h2>
                <h6 *ngIf="location != name">{{ location }}</h6>
                <div class="datos">
                    <h4>{{ address }}</h4>
                    <h4>Tel. {{ phone }}</h4>
                </div>
                <div class="datos">
                    <h4>
                        {{ record.dateCreated | date: 'dd/MM/yyyy' }}
                    </h4>
                    <h4>
                        {{ record.dateCreated | date: 'hh:mm a' }}
                    </h4>
                </div>
            </div>
        </div>
        <div *ngIf="icon.length == 0">
            <h2>{{ name }}</h2>
            <h6 *ngIf="location != name">{{ location }}</h6>
            <div *ngIf="icon.length == 0" class="datos">
                <h4>{{ address }}</h4>
                <h4>Tel. {{ phone }}</h4>
            </div>
            <div class="datos">
                <h4>
                    {{ record.dateCreated | date: 'dd/MM/yyyy' }}
                </h4>
                <h4>
                    {{ record.dateCreated | date: 'hh:mm a' }}
                </h4>
            </div>
        </div>
        <h5>
            {{ record.recordTypeId == 1 ? 'Ingresos ' : 'Egresos ' }} Recibo
            No.&nbsp;<span>{{ record.ticketNo?.padStart(5, '0') }}</span>
        </h5>
        <div class="nombre">
            <label
                >{{
                    record.recordTypeId == 1 ? 'Recibí del' : 'Pagado al'
                }}
                Sr.(a):</label
            >
            <p>{{ record.payee }}</p>
        </div>
        <div class="nombre">
            <label>Concepto:</label>
            <p>{{ category }}</p>
        </div>
        <div class="nombre">
            <label>Descripcion:</label>
            <p>{{ record.notes }}</p>
        </div>
        <!--<div *ngIf="record.locationId" class="nombre">
            <label>Lugar:</label>
            <p>{{ location }}</p>
        </div>-->
        <div class="eventos">
            <p *ngFor="let event of record.events">
                <!--<b>{{ event.description }}</b>-->
                {{ event.eventDateStart | date: 'dd/MM/YYY' }}
                {{ event.eventTimeStart }} -
                {{ event.eventDateEnd | date: 'dd/MM/YYY' }} -{{
                    record.events[0].eventTimeEnd
                }},
            </p>
        </div>

        <hr />

        <div class="nombre">
            <label>Total:</label>
            <p>{{ record.amount | currency }}</p>
        </div>

        <p *ngIf="record.recordTypeId == 1" class="info">
            {{ footer }}<br />
            <b>GRACIAS POR SU VISITA</b>
        </p>
    </div>

    <div
        *ngIf="showOutcomeDuplicate || record.recordTypeId == 1"
        class="container ticket"
    >
        <div class="title-icon" *ngIf="icon.length > 0">
            <img src="./assets/img/temp/{{ icon }}" />
            <div>
                <h2>{{ name }}</h2>
                <h6 *ngIf="location != name">{{ location }}</h6>
                <div class="datos">
                    <h4>{{ address }}</h4>
                    <h4>Tel. {{ phone }}</h4>
                </div>
                <div class="datos">
                    <h4>
                        {{ record.dateCreated | date: 'dd/MM/yyyy' }}
                    </h4>
                    <h4>
                        {{ record.dateCreated | date: 'hh:mm a' }}
                    </h4>
                </div>
            </div>
        </div>
        <div *ngIf="icon.length == 0">
            <h2>{{ name }}</h2>
            <h6 *ngIf="location != name">{{ location }}</h6>
            <div *ngIf="icon.length == 0" class="datos">
                <h4>{{ address }}</h4>
                <h4>Tel. {{ phone }}</h4>
            </div>
            <div class="datos">
                <h4>
                    {{ record.dateCreated | date: 'dd/MM/yyyy' }}
                </h4>
                <h4>
                    {{ record.dateCreated | date: 'hh:mm a' }}
                </h4>
            </div>
        </div>
        <h5>
            {{ record.recordTypeId == 1 ? 'Ingresos ' : 'Egresos ' }}Recibo
            No.&nbsp;<span>{{ record.ticketNo?.padStart(5, '0') }}</span>
        </h5>
        <div class="nombre">
            <label
                >{{
                    record.recordTypeId == 1 ? 'Recibí del' : 'Pagado al'
                }}
                Sr.(a):</label
            >
            <p>{{ record.payee }}</p>
        </div>
        <div class="nombre">
            <label>Concepto:</label>
            <p>{{ category }}</p>
        </div>
        <div class="nombre">
            <label>Descripción:</label>
            <p>{{ record.notes }}</p>
        </div>
        <!--<div *ngIf="record.locationId" class="nombre">
            <label>Lugar:</label>
            <p>{{ location }}</p>
        </div>-->
        <div class="eventos">
            <p *ngFor="let event of record.events">
                <b>{{ event.description }}</b>
                {{ event.eventDateStart | date: 'dd/MM/YYY' }}
                {{ event.eventTimeStart }} -
                {{ event.eventDateEnd | date: 'dd/MM/YYY' }} -{{
                    record.events[0].eventTimeEnd
                }},
            </p>
        </div>

        <hr />

        <div class="nombre">
            <label>Total:</label>
            <p>{{ record.amount | currency }}</p>
        </div>
        <div
            *ngIf="record.recordTypeId == 1"
            class="additional"
            [innerHTML]="additionalHTML"
        ></div>
        <p *ngIf="record.recordTypeId == 1" class="info">
            {{ footer }}<br />
            <b>GRACIAS POR SU VISITA</b>
        </p>
        <div class="ecclesia">
            <img src="../../../../../assets/img/logo_simple.png" />
            <p>e-cclesia- Sistema de administración</p>
        </div>
    </div>
</div>
