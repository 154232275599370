import { DatePipe } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import {
    FormControl,
    UntypedFormControl,
    UntypedFormGroup,
} from '@angular/forms'
import {
    MomentDateAdapter,
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter'
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
} from '@angular/material/core'
import { MatDatepicker } from '@angular/material/datepicker'
import { MatTableDataSource } from '@angular/material/table'

import * as _moment from 'moment'
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment'
import {
    IDataBalanceSecondLayer,
    ISubcategoriesBalance,
} from 'src/app/core/interfaces/general-balance'
import { CashFlowService } from 'src/app/core/services/api/cash-flow.service'
import { GeneralBalanceService } from 'src/app/core/services/api/general-balance.service'

const moment = _rollupMoment || _moment

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
}

@Component({
    selector: 'app-mensual-balance',
    templateUrl: './mensual-balance.component.html',
    styleUrls: ['./mensual-balance.component.scss'],
    providers: [
        // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
        // application's root module. We provide it at the component level here, due to limitations of
        // our example generation script.
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },

        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
})
export class MensualBalanceComponent implements OnInit {
    private _assetsDataSources: IDataTable[] = []
    private _outcomeDataSources: IDataTable[] = []
    private _previous_balance: number = 0
    private _total_income: number = 0
    private _total_outcome: number = 0
    private _range = new UntypedFormGroup({
        start: new UntypedFormControl(null),
        end: new UntypedFormControl(null),
    })
    private _maxDate = new Date()
    displayedColumns = ['name', 'count', 'total']
    date!: Moment | null

    dateString = ''
    get assetsDataSources() {
        return this._assetsDataSources
    }
    get outcomeDataSources() {
        return this._outcomeDataSources
    }
    get total_income() {
        return this._total_income
    }

    get total_outcome() {
        return this._total_outcome
    }
    get range() {
        return this._range
    }
    get fromDate() {
        return this.range?.get('start')?.value
    }
    get toDate() {
        return this.range?.get('end')?.value
    }
    get previous_balance() {
        return this._previous_balance
    }
    get maxDate() {
        return this._maxDate
    }
    constructor(private generalBalanceService: GeneralBalanceService, private cashFlowService: CashFlowService, private datePipe: DatePipe) {}

    setRange() {
        this.date = null
        if (this.fromDate && this.toDate) {
            let startDate = this.fromDate.format('MM/DD/YYYY')
            let endDate = this.toDate.format('MM/DD/YYYY')
            this.getData(startDate, endDate)
            this.getHistoricData(
                new Date(startDate)
            )
        }
    }
    convertToRange(date: Moment) {
        const currentDate = date.format('MM/DD/YYYY')
        let start = date.startOf('month').format('MM/DD/YYYY')
        let end = date.endOf('month')
        let endDate = ''
        if (end > moment()) {
            endDate = currentDate
        } else {
            endDate = moment().format('MM/DD/YYYY')
        }
        this.range?.get('start')?.patchValue(moment(start, 'MM/DD/YYYY'))
        this.range?.get('end')?.patchValue(moment(endDate, 'MM/DD/YYYY'))
        this.getData(start, endDate)
        this.getHistoricData(new Date(start))
    }
    ngOnInit(): void {
        this.date = moment()
        this.convertToRange(this.date)
        this.dateString = `${this.fromDate.format(
            'DD/MM/YYYY'
        )} - ${this.toDate.format('DD/MM/YYYY')}`
    }
    private getData(startDate: string, endDate: string) {
        this.generalBalanceService.get(startDate, endDate).subscribe((data) => {
            if (data !== null) {
                this.generateAssetsTables(data.Data.Data[0])
                this.generateOutcomeTables(data.Data.Data[1])
            }
            //this.generateAssetsTables(data.Data.Data)
        })
    }
    private getHistoricData(date:Date) {
        this._previous_balance = 0
        const currentYear = date.getFullYear()
        date.setDate(date.getDate() - 1)
        if(date.getFullYear() != currentYear) {
return
}
        
        const month = this.datePipe.transform(date, 'MM') ?? ''
        const year = this.datePipe.transform(date, 'YYYY') ?? ''

        this.cashFlowService.get(month, year).subscribe((response) => {
            if (response !== null) {
                const dateFormat = this.datePipe.transform(date, 'M/d/YYYY')
                const dateArray:any[] = response.Data.Data
                const dateFiltered:any = dateArray.filter(d => d.Key == dateFormat)
                if(dateFiltered != null){
                    this._previous_balance = dateFiltered[0].Saldo
                }

            }
        })
    }
    generateAssetsTables(data?: IDataBalanceSecondLayer) {
        this._assetsDataSources = []
        this._total_income = 0
        if(data == undefined){
            return
        }
        this._total_income = data.Total
        data.Categorias.forEach((category) => {
            let dataTable: IDataTable = {
                category: category.Key,
                subcategory: new MatTableDataSource<ISubcategoriesBalance>(),
                Total: category.Total,
            }
            dataTable.subcategory.data = category.Subcategorias
            this._assetsDataSources.push(dataTable)
        })
    }

    generateOutcomeTables(data?: IDataBalanceSecondLayer) {
        this._outcomeDataSources = []
        this._total_outcome = 0
        if(data == undefined){
            return
        }
        this._total_outcome = data.Total
        data.Categorias.forEach((category) => {
            let dataTable: IDataTable = {
                category: category.Key,
                subcategory: new MatTableDataSource<ISubcategoriesBalance>(),
                Total: category.Total,
            }
            dataTable.subcategory.data = category.Subcategorias
            this._outcomeDataSources.push(dataTable)
        })
    }
    print() {
        window.print()
    }
}

interface IDataTable {
    category: string
    subcategory: MatTableDataSource<ISubcategoriesBalance>
    Total?: number | undefined
}
