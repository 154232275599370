import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core'
import { RecordTypes } from 'src/app/core/const/constants'
import { ICategory } from 'src/app/core/interfaces/Category'
import { CategoriesService } from 'src/app/core/services/api/categories.service'
import { LoadingService } from 'src/app/core/services/util/loading.service'
import { AddDialogComponent } from '../../components/dialogs/add-dialog/add-dialog.component'
import {
    ConfirmationComponent,
    ConfirmationData,
} from '../../components/dialogs/confirmation/confirmation.component'
import { IRecord } from 'src/app/core/interfaces/record'
import { MatTableDataSource } from '@angular/material/table'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { DatePipe } from '@angular/common'
import { TableUtil } from '../../components/util/table-util'

@Component({
    selector: 'app-categories',
    templateUrl: './categories.component.html',
    styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit, AfterViewInit {
    displayedColumns = ['name', 'type', 'actions']
    dataSource = new MatTableDataSource()
    private _categories: ICategory[] = []
    private _filter: string = ''
    get filter() {
        return this._filter
    }
    set filter(filter: string) {
        this._filter = filter
    }

    constructor(
        private categoriesService: CategoriesService,
        private loadingService: LoadingService,
        public dialog: MatDialog,
        private changeDetRef: ChangeDetectorRef,
        private datePipe: DatePipe
    ) {}

    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator
    ngOnInit(): void {
        this.loadData()
        this.paginator._changePageSize(this.paginator.pageSize)
    }
    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator
    }
    loadData() {
        this.categoriesService.categories.subscribe((result) => {
            console.log('data', result)
            this._categories = result
            this.dataSource.data = result
            this.changeDetRef.detectChanges()
        })
    }
    getRecordType(id: number) {
        return RecordTypes[id]
    }
    refresh() {
        this.categoriesService.updateData()
    }
    addNew() {
        const dialogRef = this.dialog.open(AddDialogComponent, {
            data: {},
        })

        dialogRef.afterClosed().subscribe((result) => {
            console.log(result)
            if (result) {
                this.loadingService.show()
                this.categoriesService.post(result).subscribe((response) => {
                    this.loadingService.hide()
                    this.refresh()
                })
            }
        })
    }
    applyFilter() {
        const filterValue = this._filter
        this.dataSource.filter = filterValue.trim().toLowerCase()
    }
    startEdit(category: IRecord, id: number) {
        const dialogRef = this.dialog.open(AddDialogComponent, {
            data: category,
        })

        dialogRef.afterClosed().subscribe((result) => {
            console.log(result)
            if (result) {
                this.loadingService.show()
                this.categoriesService.put(result, id).subscribe((response) => {
                    this.loadingService.hide()
                    this.refresh()
                })
            }
        })
    }
    deleteItem(id: number) {
        const data = new ConfirmationData()
        data.description =
            'Estas seguro que deseas eliminar la categoria? Se eliminaran todos los registros y subcategorias relacionados.'
        const dialogRef = this.dialog.open(ConfirmationComponent, {
            data: data,
        })

        dialogRef.afterClosed().subscribe((result) => {
            console.log(result)
            if (result) {
                this.loadingService.show()
                console.log(id)
                this.categoriesService.delete(id).subscribe((response) => {
                    this.loadingService.hide()
                    this.refresh()
                })
            }
        })
    }

    exportTable() {
        let data = this.dataSource.filteredData as ICategory[]
        const parsedData: Partial<any>[] = data.map((x) => ({
            Nombre: x.name,
            Tipo: x.recordTypeId == 1 ? 'Ingreso' : 'Egreso',
        }))
        let currentDate = this.datePipe.transform(
            new Date(),
            'dd.MM.yyyy-hh.mm'
        )
        TableUtil.exportArrayToExcel(parsedData, 'Categorias' + currentDate)
    }
}
