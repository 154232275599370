import { Component, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import {
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    MomentDateAdapter,
} from '@angular/material-moment-adapter'
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
} from '@angular/material/core'
import { MatDatepicker } from '@angular/material/datepicker'
import { MatTableDataSource } from '@angular/material/table'
import * as _moment from 'moment'
import { default as _rollupMoment, Moment } from 'moment'
import { CashFlowService } from 'src/app/core/services/api/cash-flow.service'
import { LoadingService } from 'src/app/core/services/util/loading.service'

const moment = _rollupMoment || _moment

export const MY_FORMATS = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
}

@Component({
    selector: 'app-cash-flow',
    templateUrl: './cash-flow.component.html',
    styleUrls: ['./cash-flow.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },

        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
})
export class CashFlowComponent implements OnInit {
    date = new FormControl(moment())
    maxDate = new Date()
    displayedColumns = ['title', 'count', 'total']
    dateString = moment().format('MM/YYYY')
    private _dataSources: IDataTable[] = []
    get dataSources() {
        return this._dataSources
    }
    constructor(
        private cashFlowService: CashFlowService,
        private loadingService: LoadingService
    ) {}

    ngOnInit(): void {
        this.getData(this.date.value!)
    }
    setMonthAndYear(
        normalizedMonthAndYear: Moment,
        datepicker: MatDatepicker<Moment>
    ) {
        this.dateString = normalizedMonthAndYear.format('MM/YYYY')
        const ctrlValue = this.date.value!
        ctrlValue.month(normalizedMonthAndYear.month())
        ctrlValue.year(normalizedMonthAndYear.year())
        this.date.setValue(ctrlValue)
        this.getData(this.date.value!)
        datepicker.close()
    }
    print() {
        window.print()
    }
    private getData(date: Moment) {
        let month = date.format('MM')
        let year = date.format('YYYY')
        console.log(month, year)

        this.cashFlowService.get(month, year).subscribe((response) => {
            if (response !== null) {
                this.generateDataSources(response.Data.Data)
            }
        })
    }
    private generateDataSources(data: any) {
        this._dataSources = []
        this.loadingService.show()
        data.forEach((days: any) => {
            let dataTable: IDataTable = {
                date: days.Key,
                income: new MatTableDataSource<IDataSource>(),
                outcome: new MatTableDataSource<IDataSource>(),
                total_income: 0,
                total_outcome: 0,
                balance: days.Saldo,
            }
            days.Registros.forEach((registry: any) => {
                let type = registry.Key
                let table: IDataSource[] = []
                registry.Categorias.forEach((category: any) => {
                    table.push({
                        title: category.Key,
                        count: category.Count,
                        total: undefined,
                        style: 'category',
                    })
                    category.Subcategorias.forEach((subcategory: any) => {
                        table.push({
                            title: subcategory.Key,
                            count: subcategory.Count,
                            total: subcategory.Total,
                            style: '',
                        })
                    })
                    table.push({
                        title: 'Total ' + category.Key,
                        count: undefined,
                        total: category.Total,
                        style: 'category',
                    })
                    table.push({
                        title: '',
                        count: undefined,
                        total: undefined,
                        style: '',
                    })
                })
                if (type === 'Ingresos') {
                    dataTable.income.data = table
                    dataTable.total_income = registry.Total
                } else {
                    dataTable.outcome.data = table
                    dataTable.total_outcome = registry.Total
                }
            })
            this.dataSources.push(dataTable)
        })
        this.loadingService.hide()
    }
}

interface IDataTable {
    date: Date
    income: MatTableDataSource<IDataSource>
    outcome: MatTableDataSource<IDataSource>
    total_income: number
    total_outcome: number
    balance: number
}
interface IDataSource {
    title: string
    count?: number | undefined
    total?: number | undefined
    style: string
}
