import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { PROD_API_URL } from '../../const/constants'

@Injectable({
    providedIn: 'root',
})
export class CashFlowService {
    constructor(private httpService: HttpClient) {}

    get(month: string, year: string): Observable<any> {
        return this.httpService.get<any>(
            PROD_API_URL +
                '/api/reports/FlujoDiarioMensual?month=' +
                month +
                '&year=' +
                year
        )
    }
}
