import { Injectable } from '@angular/core'
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router'
import { CookieService } from 'ngx-cookie-service'
import { JWT_TOKEN } from '../../const/constants'
import { SessionManagementService } from './session-management.service'

@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(private cookieService: CookieService, public router: Router) {}
    canActivate(): boolean {
        let token = this.cookieService.get(JWT_TOKEN)
        if (token.length <= 0) {
            this.router.navigate(['login'])
            console.log('not logged')
            return false
        }
        return true
    }
}
