<div class="page-signin-modal modal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="box m-a-0">
                <div class="box-row">
                    <div class="box-cell col-md-6 bg-primary p-a-4">
                        <div class="text-xs-center text-md-left">
                            <img src="./assets/img/logo.png" />
                        </div>
                    </div>

                    <div class="box-cell col-md-7">
                        <!-- Sign In form -->
                        <form
                            action="index.html"
                            class="p-a-4"
                            id="page-signin-form"
                            [formGroup]="loginForm"
                            (ngSubmit)="login()"
                        >
                            <h4
                                class="m-t-0 m-b-4 text-xs-center font-weight-semibold"
                            >
                                ¡Bienvenid@!
                            </h4>
                            <div *ngIf="error" class="error_message">
                                <mat-icon>error</mat-icon>
                                <p>La información que ingresó es incorrecta</p>
                            </div>
                            <fieldset
                                class="page-signin-form-group form-group form-group-lg"
                            >
                                <div class="page-signin-icon text-muted">
                                    <mat-icon>person</mat-icon>
                                </div>
                                <input
                                    type="text"
                                    class="page-signin-form-control form-control"
                                    placeholder="Usuario"
                                    formControlName="username"
                                />
                            </fieldset>

                            <fieldset
                                class="page-signin-form-group form-group form-group-lg"
                            >
                                <div class="page-signin-icon text-muted">
                                    <mat-icon
                                        aria-hidden="false"
                                        aria-label="Example home icon"
                                        >lock</mat-icon
                                    >
                                </div>
                                <input
                                    type="password"
                                    class="page-signin-form-control form-control"
                                    placeholder="Contraseña"
                                    formControlName="password"
                                />
                            </fieldset>

                            <div class="clearfix">
                                <a
                                    href="#"
                                    class="font-size-12 text-muted pull-xs-right"
                                    id="page-signin-forgot-link"
                                    >¿No recuerdas tu contraseña?</a
                                >
                            </div>

                            <button
                                type="submit"
                                class="btn btn-block btn-lg btn-primary m-t-3"
                            >
                                Entrar
                            </button>
                        </form>

                        <!-- / Sign In form -->

                        <!-- Reset form -->

                        <form
                            action="index.html"
                            class="p-a-4"
                            id="page-signin-forgot-form"
                        >
                            <h4
                                class="m-t-0 m-b-4 text-xs-center font-weight-semibold"
                            >
                                Password reset
                            </h4>

                            <fieldset
                                class="page-signin-form-group form-group form-group-lg"
                            >
                                <div class="page-signin-icon text-muted">
                                    <i class="ion-at"></i>
                                </div>
                                <input
                                    type="email"
                                    class="page-signin-form-control form-control"
                                    placeholder="Your Email"
                                />
                            </fieldset>

                            <button
                                type="submit"
                                class="btn btn-block btn-lg btn-primary m-t-3"
                            >
                                Send password reset link
                            </button>
                            <div class="m-t-2 text-muted">
                                <a href="#" id="page-signin-forgot-back"
                                    >&larr; Back</a
                                >
                            </div>
                        </form>

                        <!-- / Reset form -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
