<div class="events-dialog-container container">
    <!--<button class="button-back" mat-button (click)="back()">Regresar</button>-->
    <h3>Eventos para: <span>{{eventData.recordTitle}}</span></h3>
    <form  *ngIf="eventsForm" [formGroup]="eventsForm" autocomplete="off">
        <ng-container formArrayName="eventRow">
            <table  mat-table [dataSource]="eventsDataSource" class="table2 mat-elevation-z8">
                <!-- Event Title -->
                <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef>Titulo</th>
                    <td class="issue-cell" mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
                        <mat-icon [matTooltip]="'El evento no se guardara hasta corregir la fecha'" *ngIf="eventsForm.get('eventRow')?.value[i].HasIssue">warning</mat-icon>
                        <mat-form-field
                            [ngClass]="
                                eventsForm.get('eventRow')?.value[i].IsReadOnly ? 'nonEditable' : ''
                            "
                        >
                            <input
                                matInput
                                type="text"
                                formControlName="Title"
                                [readonly]="eventsForm.get('eventRow')?.value[i].IsReadOnly"
                            />
                            <mat-error *ngIf="eventsForm.get('eventRow')?.invalid">
                                Campo requerido
                            </mat-error>
                        </mat-form-field>
                        
                    </td>
                </ng-container>
                <!-- Start Date -->
                <ng-container matColumnDef="startDate">
                    <th mat-header-cell *matHeaderCellDef>Fecha de Inicio</th>
                    <td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
                        <mat-form-field
                            class="time-date"
                            [ngClass]="
                                eventsForm.get('eventRow')?.value[i].IsReadOnly ? 'nonEditable' : ''
                            "
                        >
                            <input
                                matInput
                                (dateChange)="onStartDateChange(i)"
                                [matDatepicker]="picker"
                                formControlName="StartDate"
                                [readonly]="eventsForm.get('eventRow')?.value[i].IsReadOnly"
                                [min]="today"
                            />
                            <mat-hint>DD/MM/YYYY</mat-hint>
                            <mat-error *ngIf="eventsForm.get('eventRow')?.invalid">
                                Campo requerido
                            </mat-error>
                            <mat-datepicker-toggle
                                matIconSuffix
                                [disabled]="eventsForm.get('eventRow')?.value[i].IsReadOnly"
                                [for]="picker"
                            ></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            
                        </mat-form-field>
                        
                    </td>
                </ng-container>
                <!-- Start Time -->
                <ng-container matColumnDef="startTime">
                    <th mat-header-cell *matHeaderCellDef>Hora de Inicio</th>
                    <td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
                        <mat-form-field
                            class="time-date"
                            [ngClass]="
                                eventsForm.get('eventRow')?.value[i].IsReadOnly ? 'nonEditable' : ''
                            "
                        >
                            <input
                                matInput
                                (timeSet)="onStartTimeChange(i)"
                                [ngxMatTimepicker]="pickerA"
                                formControlName="StartTime"
                                [readonly]="eventsForm.get('eventRow')?.value[i].IsReadOnly"
                            />
                            <mat-error *ngIf="eventsForm.get('eventRow')?.invalid">
                                Campo requerido
                            </mat-error>
                            <button  mat-mini-fab
                             matSuffix (click)="pickerA.open()" [disabled]="eventsForm.get('eventRow')?.value[i].IsReadOnly">
                                <mat-icon color="accent" >
                                    watch_later
                                </mat-icon>
                            </button>
                        </mat-form-field>
                        <ngx-mat-timepicker #pickerA></ngx-mat-timepicker>
                    </td>
                </ng-container>
                <!-- End Date -->
                <ng-container matColumnDef="endDate">
                    <th mat-header-cell *matHeaderCellDef>Fecha Final</th>
                    <td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
                        <mat-form-field
                            class="time-date"
                            [ngClass]="
                                eventsForm.get('eventRow')?.value[i].IsReadOnly ? 'nonEditable' : ''
                            "
                        >
                            <input
                                matInput
                                [matDatepicker]="picker2"
                                formControlName="EndDate"
                                [readonly]="eventsForm.get('eventRow')?.value[i].IsReadOnly"
                                [min]="today"
                            />
                            <mat-error *ngIf="eventsForm.get('eventRow')?.invalid">
                                Campo requerido
                            </mat-error>
                            <mat-hint>DD/MM/YYYY</mat-hint>
                            <mat-datepicker-toggle
                            color="accent"
                                matIconSuffix
                                [disabled]="eventsForm.get('eventRow')?.value[i].IsReadOnly"
                                [for]="picker2"
                            ></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                    </td>
                </ng-container>
                <!-- End Time -->
                <ng-container matColumnDef="endTime">
                    <th mat-header-cell *matHeaderCellDef>Hora Final</th>
                    <td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
                        <mat-form-field
                            class="time-date"
                            [ngClass]="
                                eventsForm.get('eventRow')?.value[i].IsReadOnly ? 'nonEditable' : ''
                            "
                        >
                            <input
                                matInput
                                [ngxMatTimepicker]="pickerB"
                                formControlName="EndTime"
                                [readonly]="eventsForm.get('eventRow')?.value[i].IsReadOnly"
                            />
                            <mat-error *ngIf="eventsForm.get('eventRow')?.invalid">
                                Campo requerido
                            </mat-error>
                            <button  mat-mini-fab
                             matSuffix (click)="pickerB.open()" [disabled]="eventsForm.get('eventRow')?.value[i].IsReadOnly">
                                <mat-icon color="accent" >
                                    watch_later
                                </mat-icon>
                            </button>
                            
                        </mat-form-field>
                        <ngx-mat-timepicker #pickerB></ngx-mat-timepicker>
                    </td>
                </ng-container>
                <!-- Start Date -->
                <ng-container matColumnDef="restricted">
                    <th mat-header-cell *matHeaderCellDef>Fecha Exclusiva</th>
                    <td mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
                        
                            <mat-checkbox
                                formControlName="Restricted"
                                [disabled]="eventsForm.get('eventRow')?.value[i].IsReadOnly"
                            />
                    </td>
                </ng-container>
                <!-- Actions-->

                <ng-container matColumnDef="action">
                    <th class="short-column" mat-header-cell *matHeaderCellDef>
                        <div class="action-row">
                            <span>Acciones</span>
                            <mat-icon (click)="addEvent()">add_circle</mat-icon>
                        </div>
                    </th>
                    <td  mat-cell *matCellDef="let element; let i = index" [formGroup]="element">
                        <div class="actions">
                            <button
                            mat-icon-button
                            class="material-icons app-toolbar-menu save-button"
                            matTooltip="Guardar Cambios"
                            (click)="save(i)"
                            *ngIf="!eventsForm.get('eventRow')?.value[i].IsReadOnly"
                            color="primary"
                        >
                            <mat-icon>check_circle</mat-icon>
                        </button>
                        <button
                            mat-icon-button
                            class="material-icons app-toolbar-menu cancel-button"
                            matTooltip="Editar Cambios"
                            color="warn"
                            (click)="edit(i)"
                            *ngIf="eventsForm.get('eventRow')?.value[i].IsReadOnly"
                        >
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button
                            mat-icon-button
                            class="material-icons app-toolbar-menu cancel-button"
                            matTooltip="Editar Cambios"
                            color="warn"
                            (click)="confirmDelete(i)"
                            *ngIf="eventsForm.get('eventRow')?.value[i].IsReadOnly"
                        >
                            <mat-icon>delete</mat-icon>
                        </button>
                        <button
                            mat-icon-button
                            class="material-icons app-toolbar-menu cancel-button"
                            matTooltip="Cancelar Cambios"
                            color="warn"
                            (click)="cancel(i)"
                            *ngIf="!eventsForm.get('eventRow')?.value[i].IsReadOnly"
                        >
                            <mat-icon>cancel</mat-icon>
                        </button>
                        </div>
                        
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
            <div *ngIf="!eventsDataSource?.data" class="first-event">
                <h6>No hay eventos asociados con este registro</h6>
                <button mat-button (click)="addEvent()"><mat-icon>add</mat-icon>Agregar Evento</button>
            </div>
        </ng-container>
    </form>
    
</div>