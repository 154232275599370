import {
    Component,
    Output,
    Input,
    OnInit,
    ViewEncapsulation,
    EventEmitter,
    OnChanges,
    SimpleChanges,
} from '@angular/core'
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms'
import {
    MatDialog,
} from '@angular/material/dialog'
import { MatTableDataSource } from '@angular/material/table'
import { IEvent } from 'src/app/core/interfaces/event'
import { DateUtil } from '../util/date-util'
import { EventsService } from 'src/app/core/services/api/events.service'
import { RecordsService } from 'src/app/core/services/api/records.service'
import { DatePipe } from '@angular/common'
import {
    ConfirmationComponent,
    ConfirmationData,
} from '../dialogs/confirmation/confirmation.component'
import { LoadingService } from 'src/app/core/services/util/loading.service'
import { SnackbarService } from 'src/app/core/services/util/snackbar.service'
import { HttpErrorResponse } from '@angular/common/http'

@Component({
    selector: 'app-events',
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class EventsComponent implements OnInit, OnChanges {
    today = new Date()
    private _eventsForm!: FormGroup
    private _eventsDataSource!: MatTableDataSource<any>
    private _events: IEvent[] = []
    private _displayedColumns = [
        'title',
        'startDate',
        'startTime',
        'endDate',
        'endTime',
        'restricted',
        'action',
    ]
    get eventsForm() {
        return this._eventsForm
    }
    get eventsDataSource() {
        return this._eventsDataSource
    }
    get displayedColumns() {
        return this._displayedColumns
    }
    get events() {
        return this._events
    }
    @Input() eventData!: IEventData
    @Output() onBack = new EventEmitter()
    constructor(
        private formBuilder: FormBuilder,
        private eventsService: EventsService,
        private recordsService: RecordsService,
        private snackbarSevice: SnackbarService,
        private dialog: MatDialog,
        private loadingService: LoadingService,
        private datePipe: DatePipe
    ) {}
    ngOnChanges(changes: SimpleChanges): void {
        if (this.eventData.recordId != -1) {
            this.recordsService
                .eventsByRecord(this.eventData.recordId)
                .subscribe((record) => {
                    this._events = record.events
                    this.setDataSource(this._events)
                    
                })
        }
    }
    ngOnInit(): void {
        this._eventsForm = this.formBuilder.group({
            eventRow: this.formBuilder.array([]),
        })
        console.log(this.eventData)
    }
    onStartDateChange(index:number){
        const control = this.eventsForm.get('eventRow') as FormArray
        const form = control.at(index)
        const date = form.get('StartDate')?.value
        form.get('EndDate')?.patchValue(date)
    }
    onStartTimeChange(index:number){
        const control = this.eventsForm.get('eventRow') as FormArray
        const form = control.at(index)
        const date = form.get('StartTime')?.value
        const datePlusHour = new Date(date).setHours(new Date(date).getHours() + 1)
        form.get('EndTime')?.patchValue(this.datePipe.transform(datePlusHour, 'HH:mm'))
    }
    private setDataSource(events: IEvent[]) {
        this._eventsForm = this.formBuilder.group({
            eventRow: this.formBuilder.array(
                events.map((event) =>
                    this.formBuilder.group({
                        Id: new FormControl(event.id),
                        Title: new FormControl(event.description, {
                            validators: Validators.required,
                        }),
                        StartDate: new FormControl(
                            DateUtil.convertDateStringToDate(
                                event.eventDateStart
                            ),
                            { validators: Validators.required }
                        ),
                        StartTime: new FormControl(
                            DateUtil.convertTo12HourFormat(
                                event.eventTimeStart
                            ),
                            { validators: Validators.required }
                        ),
                        EndDate: new FormControl(
                            DateUtil.convertDateStringToDate(
                                event.eventDateEnd
                            ),
                            { validators: Validators.required }
                        ),
                        EndTime: new FormControl(
                            DateUtil.convertTo12HourFormat(event.eventTimeEnd),
                            { validators: Validators.required }
                        ),
                        Restricted: new FormControl({
                            value: event.restricted,
                            disabled: true,
                        }),
                        IsReadOnly: new FormControl(true),
                        HasIssue: new FormControl(false),
                    })
                )
            ),
        })
        console.log(this._eventsForm)
        //this._eventsForm.get('eventRow')
        this._eventsDataSource = new MatTableDataSource(
            (this._eventsForm.get('eventRow') as FormArray).controls
        )
        this.addDefaultEvent()
        
    }
    private addDefaultEvent(){
        const control = this.eventsForm.get('eventRow') as FormArray
        this._events.unshift({
            recordId: 0,
            description: '',
            eventDateStart: '',
            eventTimeStart: '',
            eventDateEnd: '',
            eventTimeEnd: '',
            restricted: false
        })
        control.insert(0, this.initiateUserForm(this.eventData.defaultEvent))
    }
    private initiateUserForm(event:IEvent | null = null) {
        if(event != null){
            return this.formBuilder.group({
                Title: new FormControl(event?.description, { validators: Validators.required }),
                StartDate: new FormControl(
                    DateUtil.convertDateStringToDate(
                        event.eventDateStart
                    ),
                    { validators: Validators.required }
                ),
                StartTime: new FormControl(
                    DateUtil.convertTo12HourFormat(
                        event.eventTimeStart
                    ),
                    { validators: Validators.required }
                ),
                EndDate: new FormControl(
                    DateUtil.convertDateStringToDate(
                        event.eventDateEnd
                    ),
                    { validators: Validators.required }
                ),
                EndTime: new FormControl(
                    DateUtil.convertTo12HourFormat(event.eventTimeEnd),
                    { validators: Validators.required }
                ),
                Restricted: new FormControl(false),
                IsReadOnly: new FormControl(false),
                HasIssue: new FormControl(false),
                isNew: true,
            })
        }
        return this.formBuilder.group({
            Title: new FormControl('', { validators: Validators.required }),
            StartDate: new FormControl('', { validators: Validators.required }),
            StartTime: new FormControl('', { validators: Validators.required }),
            EndDate: new FormControl('', { validators: Validators.required }),
            EndTime: new FormControl('', { validators: Validators.required }),
            Restricted: new FormControl(false),
            IsReadOnly: new FormControl(false),
            HasIssue: new FormControl(false),
            isNew: true,
        })
    }
    addEvent() {
        const control = this.eventsForm.get('eventRow') as FormArray
        control.insert(0, this.initiateUserForm())
        this._eventsDataSource = new MatTableDataSource(control.controls)
    }
    saveAll(){
        for (var i = 0 ; i<this._eventsDataSource.data.length;i++){
            this.save(i)
        }
    }
    save(index: number) {
        const control = this.eventsForm.get('eventRow') as FormArray
        const form = control.at(index)
        if (!this.dateCorrect(form)) {
            this.snackbarSevice.warningSnackBar(
                'La fecha final debe ser mayor a fecha de inicio.'
            )
            return
        }
        if (form.valid) {
            const isNew = form.get('isNew')?.value
            form.get('HasIssue')?.patchValue(false)
            let eventBody: IEvent = {
                recordId: this.eventData.recordId,
                description: form.get('Title')?.value,
                eventDateStart:
                    this.formatDate(form.get('StartDate')?.value) ?? '',
                eventTimeStart:
                    DateUtil.convertTimeTo24HourFormat(
                        form.get('StartTime')?.value
                    ) ?? '00:00:00',
                eventDateEnd: this.formatDate(form.get('EndDate')?.value) ?? '',
                eventTimeEnd:
                    DateUtil.convertTimeTo24HourFormat(
                        form.get('EndTime')?.value
                    ) ?? '00:00:00',
                restricted: form.get('Restricted')?.value,
            }
            if (isNew) {
                this.eventsService.postEvent(eventBody).subscribe({
                    next: (event) => {
                        this._events.unshift(event)
                        this.handleSuccess(form)
                    },
                    error: (error: HttpErrorResponse) => {
                        if (error.status == 409) {
                            this.handleConflict(index)
                        }
                    },
                })
            } else {
                eventBody.id = this._events[index].id
                this.eventsService.putEvent(eventBody).subscribe({
                    next: (event) => {
                        this._events[index] = eventBody
                        this.handleSuccess(form)
                    },
                    error: (error: HttpErrorResponse) => {
                        if (error.status == 409) {
                            this.handleConflict(index)
                        }
                    },
                })
            }
        }
    }

    private handleSuccess(form: AbstractControl) {
        form.get('IsReadOnly')?.patchValue(true)
        form.get('isNew')?.patchValue(false)
    }
    private handleConflict(index: number) {
        const control = this.eventsForm.get('eventRow') as FormArray
        const row = control.at(index)
        row.get('HasIssue')?.patchValue(true)
        this.snackbarSevice.errorSnackBar(
            'La fecha seleccionada esta reservada para un evento.',
            'Cerrar',
            5000
        )
    }
    private dateCorrect(form: AbstractControl) {
        const startDate = new Date(form.get('StartDate')?.value)
        const startTime = DateUtil.convertTimeTo24HourFormat(
            form.get('StartTime')?.value
        )
        var [hoursS, minutesS] = startTime.split(':').map(Number)
        const endDate = new Date(form.get('EndDate')?.value)
        const endTime = DateUtil.convertTimeTo24HourFormat(
            form.get('EndTime')?.value
        )
        var [hoursE, minutesE] = endTime.split(':').map(Number)
        startDate.setHours(hoursS)
        startDate.setMinutes(minutesS)

        endDate.setHours(hoursE)
        endDate.setMinutes(minutesE)
        return startDate < endDate
    }
    cancel(index: number) {
        const control = this.eventsForm.get('eventRow') as FormArray
        const row = control.at(index)
        let isNew = row.get('isNew')?.value
        if (isNew) {
            control.removeAt(index)
        } else {
            control.at(index)?.get('IsReadOnly')?.patchValue(true)
        }

        this._eventsDataSource = new MatTableDataSource(control.controls)
    }

    edit(index: number) {
        const control = this.eventsForm.get('eventRow') as FormArray
        control.at(index)?.get('IsReadOnly')?.patchValue(false)
    }
    confirmDelete(index: number) {
        const data = new ConfirmationData()
        data.description = '¿Está seguro que deseas eliminar el evento?'
        const dialogRef = this.dialog.open(ConfirmationComponent, {
            data: data,
        })

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.delete(index)
            }
        })
    }
    private delete(index: number) {
        this.loadingService.show()
        const control = this.eventsForm.get('eventRow') as FormArray
        let id = control.at(index).get('Id')?.value
        if (id != null) {
            this.eventsService.deleteEvent(id).subscribe({
                next: () => {
                    this.snackbarSevice.successSnackBar(
                        'Se eliminó el evento con éxito'
                    )
                    control.removeAt(index)
                    this.eventsDataSource.data = control.controls
                    this.loadingService.hide()
                },
                error: () => {
                    this.snackbarSevice.errorSnackBar(
                        'Hubo un problema al eliminar el evento.'
                    )
                    this.loadingService.hide()
                },
            })
        }
    }

    back() {
        this._eventsDataSource.data = []
        this.onBack.emit()
    }
    private formatDate(date: Date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd')
    }
}

export interface IEventData {
    recordId: number
    recordTitle: string
    defaultEvent?: IEvent | undefined
}
