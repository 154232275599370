<div class="income-container">
    <mat-tab-group #myTabGroup>
        <mat-tab>
            <mat-card class="form-card">
                <div class="form form-top">
                    <h5>Filtros</h5>
                    <div class="filters">
                        <mat-form-field floatPlaceholder="never" color="accent">
                            <input
                                matInput
                                [(ngModel)]="filter"
                                (keyup)="customFilter()"
                                #input
                                placeholder="Texto"
                            />
                        </mat-form-field>
                        <mat-form-field floatPlaceholder="never" color="accent">
                            <mat-label>Categoria</mat-label>
                            <mat-select
                                (selectionChange)="customFilter()"
                                [(value)]="categoryFilter"
                            >
                                <mat-option value="-1">Sin Filtro</mat-option>
                                <mat-option
                                    *ngFor="let category of categories"
                                    [value]="category.categoryId"
                                >
                                    {{ category.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field floatPlaceholder="never" color="accent">
                            <mat-label>Subcategoria</mat-label>
                            <mat-select
                                (selectionChange)="customFilter()"
                                [(value)]="subcategoryFilter"
                            >
                                <mat-option value="-1">Sin Filtro</mat-option>
                                <mat-option
                                    *ngFor="let subcategory of subcategories"
                                    [value]="subcategory.subcategoryId"
                                >
                                    {{ subcategory.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Rango de fechas</mat-label>
                            <mat-date-range-input
                                [formGroup]="range"
                                [rangePicker]="picker"
                            >
                                <input
                                    matStartDate
                                    formControlName="start"
                                    placeholder="Fecha Inicial"
                                    (dateChange)="customFilter()"
                                />
                                <input
                                    matEndDate
                                    formControlName="end"
                                    placeholder="Fecha Final"
                                    (dateChange)="customFilter()"
                                />
                            </mat-date-range-input>
                            <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
                            <mat-datepicker-toggle
                                matSuffix
                                [for]="picker"
                            ></mat-datepicker-toggle>
                            <mat-date-range-picker
                                #picker
                            ></mat-date-range-picker>
                            <!--<mat-error *ngIf="range.controls['start']hasError('matStartDateInvalid')">Invalid start date</mat-error>
                        <mat-error *ngIf="range.controls['end']hasError('matEndDateInvalid')">Invalid end date</mat-error>-->
                        </mat-form-field>
                        <button
                            (click)="resetRange()"
                            class="btn-clear"
                            mat-raised-button
                        >
                            Limpiar fechas
                        </button>
                    </div>
                </div>
            </mat-card>
            <div class="table3 mat-elevation-z8 page">
                <table
                    mat-table
                    [dataSource]="dataSource"
                    matSort
                    class="mat-cell"
                >
                    <!--- Note that these columns can be defined in any order.
                        The actual rendered columns are set as a property on the row definition" -->

                    <!-- ID Column -->
                    <ng-container matColumnDef="id">
                        <mat-header-cell *matHeaderCellDef mat-sort-header
                            >Id</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row">{{ row.id }}</mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="description">
                        <mat-header-cell
                            class="description"
                            *matHeaderCellDef
                            mat-sort-header
                            >Descripcion</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="description">
                            {{ row.notes }}</mat-cell
                        >
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="amount">
                        <mat-header-cell *matHeaderCellDef mat-sort-header
                            >Cantidad</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row" class="quantity">
                            {{ row.amount | currency }}</mat-cell
                        >
                        <mat-footer-cell *matFooterCellDef>{{
                            sum() | currency
                        }}</mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="ticket">
                        <mat-header-cell *matHeaderCellDef mat-sort-header
                            >Recibo</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row">
                            {{ row.ticketNo }}</mat-cell
                        >
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="payee">
                        <mat-header-cell *matHeaderCellDef mat-sort-header
                            >Solicitante</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row">
                            {{ row.payee }}</mat-cell
                        >
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="date">
                        <mat-header-cell *matHeaderCellDef mat-sort-header
                            >Fecha</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row">
                            {{ row.dateCreated | date: 'dd/MM/yyyy' }}</mat-cell
                        >
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="category">
                        <mat-header-cell *matHeaderCellDef mat-sort-header
                            >Categoria</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row">
                            {{ getCategoryName(row.categoryId) }}
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>
                    <ng-container matColumnDef="subcategory">
                        <mat-header-cell *matHeaderCellDef mat-sort-header
                            >Subcategoria</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row">
                            {{
                                getSubcategoryName(row.subcategoryId)
                            }}</mat-cell
                        >
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>
                    <ng-container matColumnDef="location">
                        <mat-header-cell *matHeaderCellDef mat-sort-header
                            >Ubicación</mat-header-cell
                        >
                        <mat-cell *matCellDef="let row">
                            {{ getLocationName(row.locationId) }}</mat-cell
                        >
                        <mat-footer-cell *matFooterCellDef
                            >Total:</mat-footer-cell
                        >
                    </ng-container>
                    <!-- actions -->
                    <ng-container
                        matColumnDef="actions"
                        style="max-width: 100px"
                    >
                        <mat-header-cell *matHeaderCellDef>
                            <div class="actions-header">
                                <span>Acciones</span>
                                <mat-icon
                                    aria-label="Example icon-button with a heart icon"
                                    (click)="addNew()"
                                    >add</mat-icon
                                >
                            </div>
                        </mat-header-cell>

                        <mat-cell
                            class="icons-actions"
                            *matCellDef="let row; let i = index"
                        >
                            <button
                                mat-icon-button
                                color="accent"
                                (click)="startEdit(row.id, row)"
                            >
                                <mat-icon aria-label="Edit">edit</mat-icon>
                            </button>

                            <button
                                *ngIf="role === 'Administrator'"
                                mat-icon-button
                                color="accent"
                                (click)="deleteItem(row.id)"
                            >
                                <mat-icon aria-label="Delete">delete</mat-icon>
                            </button>
                            <!--<button
                                *ngIf="calendarEnabled"
                                mat-icon-button
                                color="accent"
                                (click)="getEvents(row.id, row.notes)"
                            >
                                <mat-icon>event</mat-icon>
                            </button>-->
                            <button
                                mat-icon-button
                                color="accent"
                                (click)="showTicket(row)"
                            >
                                <mat-icon aria-label="Receipt"
                                    >receipt</mat-icon
                                >
                            </button>
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <mat-header-row
                        *matHeaderRowDef="displayedColumns"
                    ></mat-header-row>
                    <mat-row
                        *matRowDef="let row; columns: displayedColumns"
                    ></mat-row>
                    <mat-footer-row
                        *matFooterRowDef="displayedColumns"
                    ></mat-footer-row>
                </table>

                <div
                    class="no-results"
                    [style.display]="dataSource.data.length === 0 ? '' : 'none'"
                >
                    No results
                </div>

                <mat-paginator
                    #paginator
                    [length]="dataSource.filteredData.length"
                    [pageIndex]="0"
                    [pageSize]="10"
                    [pageSizeOptions]="[5, 10, 25, 100]"
                >
                </mat-paginator>

                <div class="export-container">
                    <button
                        mat-raised-button
                        color="primary"
                        (click)="exportTable()"
                    >
                        Exportar Tabla
                    </button>
                </div>
            </div>
        </mat-tab>
        <mat-tab>
            <app-events
                [eventData]="eventData"
                (onBack)="changeTab(0)"
            ></app-events>
        </mat-tab>
    </mat-tab-group>
</div>
