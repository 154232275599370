import { Component, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import {
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    MomentDateAdapter,
} from '@angular/material-moment-adapter'
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
} from '@angular/material/core'
import { MatDatepicker } from '@angular/material/datepicker'
import { MatTableDataSource } from '@angular/material/table'
import * as _moment from 'moment'
import { default as _rollupMoment, Moment } from 'moment'
import { CashFlowService } from 'src/app/core/services/api/cash-flow.service'
import { LoadingService } from 'src/app/core/services/util/loading.service'

const moment = _rollupMoment || _moment

export const MY_FORMATS = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
}

@Component({
    selector: 'app-cash-flow-simple',
    templateUrl: './cash-flow-simple.component.html',
    styleUrls: ['./cash-flow-simple.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },

        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
})
export class CashFlowSimpleComponent implements OnInit {
    date = new FormControl(moment())
    maxDate = new Date()
    dateString = moment().format('MM/YYYY')
    displayedColumns = [
        'date',
        'income',
        'outcome',
        'total',
        'petty',
        'balance',
    ]
    private _dataSource = new MatTableDataSource<ICashFlow>()
    get dataSource() {
        return this._dataSource
    }
    constructor(
        private cashFlowService: CashFlowService,
        private loadingService: LoadingService
    ) {}

    ngOnInit(): void {
        this.getData(this.date.value!)
    }
    setMonthAndYear(
        normalizedMonthAndYear: Moment,
        datepicker: MatDatepicker<Moment>
    ) {
        this.dateString = normalizedMonthAndYear.format('MM/YYYY')
        const ctrlValue = this.date.value!
        ctrlValue.month(normalizedMonthAndYear.month())
        ctrlValue.year(normalizedMonthAndYear.year())
        this.date.setValue(ctrlValue)
        this.getData(this.date.value!)
        datepicker.close()
    }
    print() {
        window.print()
    }
    private getData(date: Moment) {
        let month = date.format('MM')
        let year = date.format('YYYY')
        console.log(month, year)

        this.cashFlowService.get(month, year).subscribe((response) => {
            if (response !== null) {
                this.generateDataSources(response.Data.Data)
            }
        })
    }
    private generateDataSources(data: any) {
        this.loadingService.show()
        let values: ICashFlow[] = []
        data.forEach((day: any) => {
            let cashFlow: ICashFlow = {
                date: new Date(day.Key),
                income: 0,
                outcome: 0,
                total: day.Total,
                petty: day.CantidadEntregada,
                balance: day.Saldo,
            }
            day.Registros.forEach((registry: any) => {
                if (registry.Key == 'Ingresos') {
                    cashFlow.income = registry.Total
                } else {
                    cashFlow.outcome = registry.Total
                }
            })
            cashFlow.total = cashFlow.income - cashFlow.outcome
            values.push(cashFlow)
        })
        this._dataSource.data = values
        this.loadingService.hide()
    }
}
interface ICashFlow {
    date: Date
    income: number
    outcome: number
    total: number
    petty: number
    balance: number
}
