import { CurrencyPipe } from '@angular/common'
import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { ICategory } from 'src/app/core/interfaces/Category'
import { IComparativoMensual } from 'src/app/core/interfaces/comparativoMensual'
import { ISubcategory } from 'src/app/core/interfaces/subcategory'
import { CategoriesService } from 'src/app/core/services/api/categories.service'
import { ComparisonService } from 'src/app/core/services/api/comparison-service.service'
import { SubcategoriesService } from 'src/app/core/services/api/subcategories.service'
import { LoadingService } from 'src/app/core/services/util/loading.service'

@Component({
    selector: 'app-comparison',
    templateUrl: './comparison.component.html',
    styleUrls: ['./comparison.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ComparisonComponent implements OnInit {
    private _categories: ICategory[] = []
    private _subcategories: ISubcategory[] = []
    private _filteredSubcategories: ISubcategory[] = []
    private _categoryFilter: number = -1
    private _subcategoryFilter: number = -1
    private _minDate!: string
    private _maxDate!: string
    private _columns: string[] = []
    private _tableData: ITableItem[] = []
    private _filteredTableData: ITableItem[] = []
    private _title = ''

    get columns() {
        return this._columns
    }

    get filteredTableData() {
        return this._filteredTableData
    }
    get categories() {
        return this._categories
    }

    get subcategories() {
        return this._filteredSubcategories
    }

    get categoryFilter() {
        return this._categoryFilter
    }

    get subcategoryFilter() {
        return this._subcategoryFilter
    }
    get minDate() {
        return this._minDate
    }
    get maxDate() {
        return this._maxDate
    }
    get title() {
        return this._title
    }
    set title(val: string) {
        this._title = val
    }
    set minDate(val: string) {
        this._minDate = val
    }

    set maxDate(val: string) {
        this._maxDate = val
    }
    set categoryFilter(val: number) {
        this._categoryFilter = val
    }

    set subcategoryFilter(val: number) {
        this._subcategoryFilter = val
    }
    constructor(
        private categoriesService: CategoriesService,
        private subcategoriesService: SubcategoriesService,
        private comparisonService: ComparisonService,
        private currencyPipe: CurrencyPipe,
        private loadingService: LoadingService
    ) {}

    ngOnInit(): void {
        this.categoriesService.categories.subscribe((data) => {
            this._categories = data
        })
        this.getSubcategories()
    }

    private getSubcategories(id: number = -1) {
        console.log(id)
        if (id > 0) {
            this.subcategoriesService.subcategories.subscribe((data) => {
                this._subcategories = data
            })
        } else {
            this.categoriesService
                .getById(this.categoryFilter)
                .subscribe((category) => {
                    this._filteredSubcategories = category.subcategories.sort(
                        (a, b) => a.name.localeCompare(b.name)
                    )
                })
        }
    }

    customFilter() {
        if (this.categoryFilter != -1) {
            this.getSubcategories()
            var categoryName = this.categories.filter(
                (cat) => cat.categoryId === this.categoryFilter
            )[0].name
            this._filteredTableData = this._tableData.filter(
                (data) =>
                    data['title'] == categoryName ||
                    data['parent'] == categoryName
            )
        } else {
            this._subcategories = []
            this.subcategoryFilter = -1
            this._filteredTableData = this._tableData
        }
        if (this._subcategoryFilter != -1) {
            var subcategoryName = this.subcategories.filter(
                (cat) => cat.subcategoryId === this.subcategoryFilter
            )[0].name
            this._filteredTableData = this._tableData.filter(
                (data) => data['title'] == subcategoryName
            )
        }
        //this.dataSource.filter = '' + Math.random()
    }

    rangeChanged() {
        if (this.minDate != undefined && this.maxDate != undefined) {
            this.categoryFilter = -1
            this.subcategoryFilter = -1
            this.loadingService.show()
            this.comparisonService
                .get(this.minDate, this.maxDate)
                .subscribe((comparison) => {
                    this.generateDataSource(comparison)
                })
        }
    }

    getDate(date: string) {
        return new Date(date)
    }
    generateDataSource(data: IComparativoMensual) {
        this._columns = this.generateMonths(data.DateStart, data.DateEnd)
        var colspan = 1
        console.log(data)
        data.Data.forEach((type) => {
            this._tableData.push({
                title: type['Key'],
                colspan: colspan,
                style: 'type',
            })
            type['Values'].forEach((category) => {
                this._tableData.push({
                    title: category['Key'],
                    colspan: colspan,
                    style: 'category',
                })
                var keyValueCategoryTotal: { [key: string]: any } = {}
                keyValueCategoryTotal['title'] = `Total ${category['Key']}:`
                keyValueCategoryTotal['style'] = 'category margin-bottom-row'

                category['Values'].forEach((subcategory) => {
                    var keyValue: { [key: string]: any } = {}
                    keyValue['title'] = subcategory['Key']
                    keyValue['parent'] = category['Key']
                    var total = 0

                    subcategory['Values'].forEach((comparison) => {
                        keyValue[comparison.Year] = comparison.Total
                        keyValueCategoryTotal[comparison.Year] =
                            keyValueCategoryTotal[comparison.Year] == undefined
                                ? comparison.Total
                                : keyValueCategoryTotal[comparison.Year] +
                                  comparison.Total
                        keyValueCategoryTotal['total'] =
                            keyValueCategoryTotal['total'] == undefined
                                ? comparison.Total
                                : keyValueCategoryTotal['total'] +
                                  comparison.Total
                        total += comparison.Total
                    })
                    keyValue['total'] = total
                    keyValue['average'] = total / (this.columns.length - 3)

                    this._tableData.push(keyValue)
                })
                keyValueCategoryTotal['average'] =
                    keyValueCategoryTotal['total'] / (this.columns.length - 3)
                this._tableData.push(keyValueCategoryTotal)
                this._tableData.push({})
            })
        })
        this._filteredTableData = this._tableData
        this._columns = this.generateMonths(data.DateStart, data.DateEnd)
        this.loadingService.hide()
    }
    private generateMonths(startDate: Date, endDate: Date) {
        var months: string[] = ['title']
        startDate = new Date(startDate)
        endDate = new Date(endDate)
        var currentDate = new Date(startDate)
        while (currentDate <= endDate) {
            let month = (currentDate.getMonth() + 1).toString()
            months.push(
                `${
                    month.length == 1 ? '0' + month : month
                }-${currentDate.getFullYear()}`
            )
            currentDate.setMonth(currentDate.getMonth() + 1)
        }
        months.push('total', 'average')
        return months
    }
    print() {
        window.print()
    }
    formatValue(value: any) {
        if (typeof value == 'number') {
            return this.currencyPipe.transform(value, 'USD', 'symbol', '1.2-2')
        }
        return value
    }
    getDynamicClass(value: any, style: string | null) {
        var ret = style != null ? style : ''
        if (typeof value == 'number') {
            ret += ' currency'
        }
        return ret
    }
    formatColumn(column: string) {
        switch (column) {
            case 'title':
                return ''
            case 'total':
                return 'Total'
            case 'average':
                return 'Promedio'
            default: {
                var arr = column.split('-')
                var month = this.getMonthName(arr[0])
                return `${month} ${arr[1]}`
            }
        }
    }

    getMonthName(month: string) {
        switch (month) {
            case '01':
                return 'Enero'
            case '02':
                return 'Febrero'
            case '03':
                return 'Marzo'
            case '04':
                return 'Abril'
            case '05':
                return 'Mayo'
            case '06':
                return 'Junio'
            case '07':
                return 'Julio'
            case '08':
                return 'Agosto'
            case '09':
                return 'Septiembre'
            case '10':
                return 'Octubre'
            case '11':
                return 'Noviembre'
            case '12':
                return 'Diciembre'
            default:
                return ''
        }
    }
}

interface ITableItem {
    [key: string]: any
}
