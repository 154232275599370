<!--<mat-card class="no-media">
    <div class="form form-top">
        <h2>Filtros</h2>
        <mat-form-field appearance="fill">
            <mat-label>Month and Year</mat-label>
            <input matInput [matDatepicker]="dp" [formControl]="date" />
            <mat-hint>MM/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker
                #dp
                startView="multi-year"
                (monthSelected)="setMonthAndYear($event, dp)"
                panelClass="example-month-picker"
            >
            </mat-datepicker>
        </mat-form-field>
        <button class="print_button" mat-raised-button (click)="print()">
            <mat-icon>print</mat-icon>Imprimir
        </button>
    </div>
</mat-card>-->
<div class="tables-container mat-elevation-z8">
    <div class="container table3">
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>Fecha</th>
                <td mat-cell *matCellDef="let element" [class]="element.style">
                    {{ element.dateCreated | date: 'dd/MM/yyyy' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef>Cantidad</th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    [class]="element.style"
                    class="quantity"
                >
                    {{ element.amount | currency }}
                </td>
            </ng-container>
            <ng-container matColumnDef="notes">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="actions-header">
                        <span>Notas</span>
                        <mat-icon
                            aria-label="Example icon-button with a heart icon"
                            (click)="addNew()"
                            >add</mat-icon
                        >
                    </div>
                </th>
                <td
                    mat-cell
                    *matCellDef="let element"
                    [class]="element.style"
                    class="notes"
                >
                    {{ element.notes }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <div class="export-container">
            <button mat-raised-button color="primary" (click)="exportTable()">
                Exportar Tabla
            </button>
        </div>
    </div>
</div>
