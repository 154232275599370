import { Component, OnInit } from '@angular/core'
import { SideNavService } from 'src/app/core/services/util/side-nav.service'

@Component({
    selector: 'app-side-nav-toggler',
    templateUrl: './side-nav-toggler.component.html',
    styleUrls: ['./side-nav-toggler.component.scss'],
})
export class SideNavTogglerComponent {
    constructor(public sideNavService: SideNavService) {}
}
