import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { IEventService } from '../../interfaces/api/event-service'
import { Observable } from 'rxjs'
import { IEvent } from '../../interfaces/event'
import { PROD_API_URL } from '../../const/constants'

const API_URL = 'api/event'

@Injectable({
    providedIn: 'root',
})
export class EventsService implements IEventService {
    constructor(private httpClient: HttpClient) {}
    getEvents(dateStart: string, dateEnd: string): Observable<IEvent[]> {
        return this.httpClient.get<IEvent[]>(
            `${PROD_API_URL}${API_URL}/ByRange?dateStart=${dateStart}&dateEnd=${dateEnd}`
        )
    }
    postEvent(event: IEvent): Observable<IEvent> {
        return this.httpClient.post<IEvent>(`${PROD_API_URL}${API_URL}`, event)
    }
    putEvent(event: IEvent): Observable<IEvent> {
        return this.httpClient.put<IEvent>(
            `${PROD_API_URL}${API_URL}/${event.id}`,
            event
        )
    }
    deleteEvent(eventId: number): Observable<void> {
        return this.httpClient.delete<void>(
            `${PROD_API_URL}${API_URL}/${eventId}`
        )
    }
}
