import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { PROD_API_URL } from '../../const/constants'
import { IDailyBalance } from '../../interfaces/daily-balance'

@Injectable({
    providedIn: 'root',
})
export class DailyBalanceReportService {
    private _dailyBalance = new Subject<IDailyBalance[]>()
    get dailyBalance() {
        return this._dailyBalance.asObservable()
    }

    constructor(private httpService: HttpClient) {}

    get(date: string): Observable<IDailyBalance[]> {
        return this.httpService.get<IDailyBalance[]>(
            PROD_API_URL + '/api/reports/IngresosDiarios?day=' + date
        )
    }
}
