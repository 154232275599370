import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog'
import { CalendarEvent } from 'angular-calendar'
import { IEvent } from 'src/app/core/interfaces/event'
import { DateUtil } from '../../util/date-util'
import { DatePipe } from '@angular/common'
import { EventsService } from 'src/app/core/services/api/events.service'
import { HttpErrorResponse } from '@angular/common/http'
import { ConfirmationComponent, ConfirmationData } from '../confirmation/confirmation.component'
import { LoadingService } from 'src/app/core/services/util/loading.service'
import { SnackbarService } from 'src/app/core/services/util/snackbar.service'

@Component({
    selector: 'app-event-detail',
    templateUrl: './event-detail.component.html',
    styleUrls: ['./event-detail.component.scss'],
})
export class EventDetailComponent implements OnInit {
    today = new Date()
    private _data!: IEvent
    private _formGroup!: FormGroup
    get data() {
        return this._data
    }
    get formGroup() {
        return this._formGroup
    }
    constructor(
        public dialogRef: MatDialogRef<EventDetailComponent>,
        @Inject(MAT_DIALOG_DATA) public event: IEvent,
        private eventService: EventsService,
        private formBuilder: FormBuilder,
        private datePipe: DatePipe,
        private dialog: MatDialog
    ) {}
    ngOnInit(): void {
        this._data = this.event
        this._formGroup = this.formBuilder.group({
            Id: new FormControl(this.event.id),
            Title: new FormControl(this.event.description, {
                validators: Validators.required,
            }),
            StartDate: new FormControl(
                DateUtil.convertDateStringToDate(this.event.eventDateStart),
                { validators: Validators.required }
            ),
            StartTime: new FormControl(
                DateUtil.convertTo12HourFormat(this.event.eventTimeStart),
                { validators: Validators.required }
            ),
            EndDate: new FormControl(
                DateUtil.convertDateStringToDate(this.event.eventDateEnd),
                { validators: Validators.required }
            ),
            EndTime: new FormControl(
                DateUtil.convertTo12HourFormat(this.event.eventTimeEnd),
                { validators: Validators.required }
            ),
            Restricted: new FormControl({
                value: this.event.restricted,
                disabled: true,
            }),
            IsReadOnly: new FormControl(true),
        })
    }
    save() {
        let eventBody: IEvent = {
            id: this.event.id,
            recordId: this.event.recordId,
            description: this.formGroup.get('Title')?.value,
            eventDateStart:
                this.formatDate(this.formGroup.get('StartDate')?.value) ?? '',
            eventTimeStart:
                DateUtil.convertTimeTo24HourFormat(
                    this.formGroup.get('StartTime')?.value
                ) ?? '00:00:00',
            eventDateEnd:
                this.formatDate(this.formGroup.get('EndDate')?.value) ?? '',
            eventTimeEnd:
                DateUtil.convertTimeTo24HourFormat(
                    this.formGroup.get('EndTime')?.value
                ) ?? '00:00:00',
            restricted: this.event.restricted,
        }
        this.eventService.putEvent(eventBody).subscribe({
            next: () => {
                this.dialogRef.close(EventDetailResponse.SAVED)
            },
            error: (error: HttpErrorResponse) => {
                if (error.status == 409) {
                    this.dialogRef.close(EventDetailResponse.CONFLICT)
                }
            },
        })
    }
    cancel() {
        this.dialogRef.close(EventDetailResponse.CANCEL)
    }
    openRecord() {
        this.dialogRef.close(EventDetailResponse.RECORD)
    }
    confirmDelete() {
        const data = new ConfirmationData()
        data.description = '¿Está seguro que deseas eliminar el evento?'
        const dialogRef = this.dialog.open(ConfirmationComponent, {
            data: data,
        })

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.delete()
            }
        })
    }
    private delete() {
        let id = this.event.id
        if (id != null) {
            this.eventService.deleteEvent(id).subscribe({
                next: () => {
                    this.dialogRef.close(EventDetailResponse.DELETED)
                },
                error: () => {
                    this.dialogRef.close(EventDetailResponse.ERROR)
                },
            })
        }
    }
    private formatDate(date: Date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd')
    }
}
export enum EventDetailResponse {
    SAVED,
    CANCEL,
    RECORD,
    CONFLICT,
    DELETED,
    ERROR
}
