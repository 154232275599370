import { Moment } from 'moment'

export class DateUtil {
    static getFormattedDateString(moment: Moment) {
        return `${MonthSpanish[moment.month()]} ${moment.year} HERE`
    }
    static convertTimeTo24HourFormat(timeString: string): string {
        // Split the time string into hours, minutes, and AM/PM parts
        const [time, period] = timeString.split(' ')

        // Split the time into hours and minutes
        const [hours, minutes] = time.split(':').map(Number)

        // Convert to 24-hour format
        let hours24 = hours
        if (period === 'PM' && hours !== 12) {
            hours24 += 12
        } else if (period === 'AM' && hours === 12) {
            hours24 = 0
        }

        // Format the hours and minutes as two-digit strings
        const hoursString = hours24.toString().padStart(2, '0')
        const minutesString = minutes.toString().padStart(2, '0')

        // Combine hours and minutes with a colon to get the 24-hour time
        const time24Hour = `${hoursString}:${minutesString}:00`

        return time24Hour
    }

    static convertTo12HourFormat(time24Hour: string): string {
        // Parse the 24-hour time string
        const [hours, minutes] = time24Hour.split(':').map(Number)

        // Determine AM or PM
        const period = hours >= 12 ? 'PM' : 'AM'

        // Convert hours to 12-hour format
        const hours12 = hours % 12 === 0 ? 12 : hours % 12

        // Format the time in 12-hour format
        const time12Hour = `${hours12.toString().padStart(2, '0')}:${minutes
            .toString()
            .padStart(2, '0')} ${period}`

        return time12Hour
    }
    static convertDateStringToDate(
        dateString: string,
        separator: string = '-'
    ): Date {
        const [year, month, day] = dateString.split(separator).map(Number)
        return new Date(year, month - 1, day)
    }

    static convertDateStringToDateDMY(
        dateString: string,
        separator: string = '-'
    ): Date {
        const [month, day, year] = dateString.split(separator).map(Number)
        return new Date(year, month - 1, day)
    }

    static AddTimeToDate(date: Date, timeString: string) {
        const [hours, minutes] = timeString.split(':').map(Number)
        const originalDate = date
        originalDate.setHours(hours)
        originalDate.setMinutes(minutes)
        return originalDate
    }
}
export enum MonthSpanish {
    Enero = 1,
    Febrero = 2,
    Marzo = 3,
}
