import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'

@Injectable({
    providedIn: 'root',
})
export class SnackbarService {
    constructor(private snackBar: MatSnackBar) {}

    errorSnackBar(message: string, action: string = 'Close', timeoutMs = 3000) {
        this.showSnackbar(message, action, timeoutMs, 'error-snackbar')
    }

    warningSnackBar(
        message: string,
        action: string = 'Close',
        timeoutMs = 3000
    ) {
        this.showSnackbar(message, action, timeoutMs, 'warning-snackbar')
    }

    successSnackBar(
        message: string,
        action: string = 'Close',
        timeoutMs = 3000
    ) {
        this.showSnackbar(message, action, timeoutMs, 'success-snackbar')
    }

    infoSnackbar(message: string, action: string = 'Close', timeoutMs = 3000) {
        this.showSnackbar(message, action, timeoutMs, 'info-snackbar')
    }

    private showSnackbar(
        message: string,
        action: string,
        timeoutMs: number,
        panelClass: string
    ) {
        let _message = message.replace('<br>', '\n')
        this.snackBar.open(_message, action, {
            panelClass: panelClass,
            duration: timeoutMs,
        })
    }
}
