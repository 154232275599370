import { DatePipe } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import {
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    MomentDateAdapter,
} from '@angular/material-moment-adapter'
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
} from '@angular/material/core'
import { MatDatepicker } from '@angular/material/datepicker'
import * as _moment from 'moment'
import { default as _rollupMoment, Moment } from 'moment'
import { IPetty } from 'src/app/core/interfaces/petty'
import { PettyService } from 'src/app/core/services/api/petty.service'
import { AddPettyComponent } from '../../components/dialogs/add-petty/add-petty.component'
import { TableUtil } from '../../components/util/table-util'
import { MatTableDataSource } from '@angular/material/table'
import { MatDialog } from '@angular/material/dialog'

const moment = _rollupMoment || _moment

export const MY_FORMATS = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
}

@Component({
    selector: 'app-petty',
    templateUrl: './petty.component.html',
    styleUrls: ['./petty.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },

        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
})
export class PettyComponent implements OnInit {
    displayedColumns = ['date', 'amount', 'notes']
    date = new FormControl(moment())
    private _dataSource = new MatTableDataSource<IPetty>()
    get dataSource() {
        return this._dataSource
    }
    constructor(
        private pettyService: PettyService,
        public dialog: MatDialog,
        public datePipe: DatePipe
    ) {}

    ngOnInit(): void {
        this.getData(this.date.value!)
    }

    setMonthAndYear(
        normalizedMonthAndYear: Moment,
        datepicker: MatDatepicker<Moment>
    ) {
        const ctrlValue = this.date.value!
        ctrlValue.month(normalizedMonthAndYear.month())
        ctrlValue.year(normalizedMonthAndYear.year())
        this.date.setValue(ctrlValue)
        this.getData(this.date.value!)
        datepicker.close()
    }
    private getData(date: Moment) {
        let month = date.format('MM')
        let year = date.format('yyyy')
        console.log(month, year)

        this.pettyService.get().subscribe((response: IPetty[]) => {
            if (response !== null) {
                this.dataSource.data = response
            }
        })
    }
    print() {
        window.print()
    }
    addNew() {
        const dialogRef = this.dialog.open(AddPettyComponent, {
            width: '400px',
        })

        dialogRef.afterClosed().subscribe((result) => {
            console.log(result)
            if (result) {
                this.pettyService.post(result).subscribe((response) => {
                    this.getData(this.date.value!)
                })
            }
        })
    }
    exportTable() {
        let data = this.dataSource.filteredData as IPetty[]
        const parsedData: Partial<any>[] = data.map((x) => ({
            Fecha: this.datePipe.transform(x.dateCreated, 'dd/MM/yyyy'),
            Cantidad: x.amount,
            Notas: x.notes,
        }))
        let currentDate = this.datePipe.transform(
            new Date(),
            'dd.MM.yyyy-HH.mm'
        )
        TableUtil.exportArrayToExcel(parsedData, 'Cantidad' + currentDate)
    }
}
