import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'
import { JWT_TOKEN, PROD_API_URL } from '../../const/constants'
import { Observable } from 'rxjs'
import { CookieService } from 'ngx-cookie-service'
import { Router } from '@angular/router'

const inactivityTimeout = 7200000

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(
        private httpService: HttpClient,
        private cookieService: CookieService,
        private router: Router
    ) {
        this.setInactivityTimer()
    }

    login(user: UntypedFormGroup): Observable<any[]> {
        return this.httpService.post<any[]>(
            PROD_API_URL + 'api/authentication/login',
            user
        )
    }

    logout() {
        sessionStorage.clear()
        this.cookieService.delete(JWT_TOKEN)
        this.router.navigateByUrl('login')
    }

    private setInactivityTimer() {
        let inactivityTimer: NodeJS.Timer

        const resetTimer = () => {
            clearTimeout(inactivityTimer)
            inactivityTimer = setTimeout(() => {
                console.log('User session expired due to inactivity')
                this.logout()
            }, inactivityTimeout)
        }

        //Reset events
        window.addEventListener('mousemove', resetTimer)
        window.addEventListener('keydown', resetTimer)
    }
}
