import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { CONFIGURATION_ITEMS } from 'src/app/core/const/constants'
import { IRecordWithEvents } from 'src/app/core/interfaces/record'
import { CategoriesService } from 'src/app/core/services/api/categories.service'
import { ConfigurationService } from 'src/app/core/services/api/configuration.service'
import { LocationsService } from 'src/app/core/services/api/locations.service'
import { RecordsService } from 'src/app/core/services/api/records.service'

@Component({
    selector: 'app-ticket',
    templateUrl: './ticket.component.html',
    styleUrls: ['./ticket.component.scss'],
})
export class TicketComponent implements OnInit {
    private _recordId: number = -1
    private _record!: IRecordWithEvents
    private _category: string = ''
    private _location: string = ''
    private _locationAddress = ''
    private _address = ''
    private _phone = ''
    private _name = ''
    private _icon = ''
    private _additionalHTML = ''
    private _showOutcomeDuplicate = false
    private _footer =
        'COMPROBANTE SIN EFECTOS FISCALES NO SE ACEPTAN CAMBIOS NI DEVOLUCIONES'
    get record() {
        return this._record
    }
    get location() {
        return this._location
    }
    get category() {
        return this._category
    }
    get address() {
        return this._address
    }
    get phone() {
        return this._phone
    }

    get name() {
        return this._name
    }
    get icon() {
        return this._icon
    }
    get additionalHTML() {
        return this._additionalHTML
    }
    get footer() {
        return this._footer
    }

    get showOutcomeDuplicate() {
        return this._showOutcomeDuplicate
    }
    constructor(
        private recordService: RecordsService,
        private categoriesService: CategoriesService,
        private locationsService: LocationsService,
        private configurationService: ConfigurationService,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.route.params.subscribe((params) => {
            this._recordId = params['id']
            this.getRecordData()
        })
    }
    private getRecordData() {
        this.recordService
            .eventsByRecord(this._recordId)
            .subscribe((record) => {
                this._record = record
                this.getCategory(record.categoryId)
                this.getLocation(record.locationId ?? -1)
            })
    }
    print() {
        window.print()
    }
    private getCategory(categoryId: number) {
        this.categoriesService.getById(categoryId).subscribe((category) => {
            this._category = category.name
        })
    }
    private getLocation(locationId: number) {
        if (locationId == -1) {
            this.getPhoneAndAddress()
            return
        }
        this.locationsService.getById(locationId).subscribe((location) => {
            this._location = location.name
            this._locationAddress = location.address
            console.log(location)
            this.getTicketData()
            //this.getPhoneAndAddress()
        })
    }
    private getTicketData(){
        this._icon = this.configurationService.retrieveByName(CONFIGURATION_ITEMS.GeneralIcon)?.value ?? ''
        this._name = this.configurationService.retrieveByName(CONFIGURATION_ITEMS.GeneralName)?.value ?? ''
        this._address = this._locationAddress.length > 0
        ? this._locationAddress
        : this.configurationService.retrieveByName(CONFIGURATION_ITEMS.TicketInfoAddress)?.value ?? ''
        this._phone = this.configurationService.retrieveByName(CONFIGURATION_ITEMS.TicketInfoPhone)?.value ?? ''
        this._additionalHTML = this.configurationService.retrieveByName(CONFIGURATION_ITEMS.TicketInfoAditionalHtml)?.value ?? ''
        this._showOutcomeDuplicate = this.configurationService.retrieveByName(CONFIGURATION_ITEMS.FFTicketDuplicateEgresos)?.value == 'true'
        this._footer = this.configurationService.retrieveByName(CONFIGURATION_ITEMS.TicketInfoFooter)?.value ?? ''
    }
    /**
     * Retrieves phone and address based on the domain, this information will come from an endpoint in the future
     */
    getPhoneAndAddress() {
        const domain = window.location.host
        const host = domain.split('.')[0]
        console.log(host)
        switch (host) {
            case 'divina-providencia':
                {
                    this._icon = 'divina-providencia.jpg'
                    this._name = 'Parroquia de la Divina Providencia'
                    this._address =
                        this._locationAddress.length > 0
                            ? this._locationAddress
                            : '14 Oriente 4215 Col. El Porvenir C.P. 72310 Puebla, Pue'
                    this._phone = '222 2 35 13 64'
                    this._additionalHTML =
                        '<b>"La Divina Misericordia se extienda a cada momento"</b>'
                }
                break
            case 'guadalupe-volcanes':
                {
                    this._name =
                        'Parroquia de Nuestra Señora de Guadalupe Volcanes'
                    this._address =
                        this._locationAddress.length > 0
                            ? this._locationAddress
                            : '35 Poniente y 17 Sur Col. Volcanes Puebla, Pue.'
                    this._phone = '222 240 6283'
                    this._icon = 'volcanes.png'
                    this._showOutcomeDuplicate = false
                    this._additionalHTML =
                        '<p><b>NOTA:</b> La celebración iniciará en punto de la hora que se indica en este recibo.</p>'
                }
                break
            case 'santuario-guadalupano':
                {
                    this._name = 'Santuario Guadalupano'
                    this._address =
                        this._locationAddress.length > 0
                            ? this._locationAddress
                            : 'Prolongación de la 16 Oriente  Col. El Porvenir C.P.72330 Puebla, Pue.'
                    this._phone = '222 234 1813'
                    this._icon = 'santuario.png'
                    this._additionalHTML =
                        '<p>Para aportaciones bancarias, realizarlas en la cuenta Arquidiócesis de Puebla Santuario <br><b>BBVA Cuenta:</b> 0182630443 Clabe: 012650001826304437 <br>www.santuarioguadalupanodepuebla.org.mx  <b>Facebook:</b> Santuario Guadalupano en Puebla</p>'
                }
                break
            case 'canoa':
                {
                    this._name = 'Parroquia de San Miguel Arcángel Canoa'
                    this._address =
                        this._locationAddress.length > 0
                            ? this._locationAddress
                            : 'Plaza Principal, sin numero. San Miguel Canoa Puebla, Pue., C.P. 72900'
                    this._phone = '222 291 0006'
                    this._icon = 'canoa.png'
                    this._showOutcomeDuplicate = false
                    this._footer =
                        'COMPROBANTE SIN EFECTOS FISCALES. FAVOR DE CONSERVAR SU RECIBO'
                    this._additionalHTML =
                        '<b>Correo electrónico</b> p.snmiguelarccanoa@gmail.com '
                }
                break
            case 'canoa-fiscales':
                {
                    this._name = 'Parroquia de San Miguel Arcángel Canoa'
                    this._address =
                        this._locationAddress.length > 0
                            ? this._locationAddress
                            : 'Plaza Principal, sin numero. San Miguel Canoa Puebla, Pue., C.P. 72900'
                    this._phone = '222 291 0006'
                    this._icon = 'canoa.png'
                    this._showOutcomeDuplicate = false
                    this._footer =
                        'COMPROBANTE SIN EFECTOS FISCALES. FAVOR DE CONSERVAR SU RECIBO'
                    this._additionalHTML = ''
                }
                break
            case 'santuario-misericordia':
                {
                    this._name = 'Santuario del Señor de la Misericordia'
                    this._address = this._locationAddress.length > 0 ? this._locationAddress :
                        'Av. Maestro Federal #1802 Col. Satélite Magisterial, Puebla, Pue. C.P. 72320'
                    this._phone = '222 620 5007'
                    this._icon = 'santuario-misericordia.jpg'
                    this._showOutcomeDuplicate = false
                    this._additionalHTML = 'Jesús en ti confio'
                }
                break
            default:
                {
                    this._name = 'This is a demo'
                    this._address =
                        this._locationAddress.length > 0
                            ? this._locationAddress
                            : 'Demo address'
                    this._phone = '222 222 2222'
                }
                break
        }
    }
}
