<mat-card class="no-media">
    <div class="form form-top">
        <h2>Filtros</h2>
        <mat-form-field floatPlaceholder="never" color="primary">
            <mat-label>Categoria</mat-label>
            <mat-select
                (selectionChange)="customFilter()"
                [(value)]="categoryFilter"
                [disabled]="
                    filteredTableData.length <= 0 && categoryFilter === -1
                "
            >
                <mat-option value="-1">Sin Filtro</mat-option>
                <mat-option
                    *ngFor="let category of categories"
                    [value]="category.categoryId"
                >
                    {{ category.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field floatPlaceholder="never" color="primary">
            <mat-label>Subcategoria</mat-label>
            <mat-select
                (selectionChange)="customFilter()"
                [(value)]="subcategoryFilter"
                [disabled]="
                    filteredTableData.length <= 0 && subcategoryFilter === -1
                "
            >
                <mat-option value="-1">Sin Filtro</mat-option>
                <mat-option
                    *ngFor="let subcategory of subcategories"
                    [value]="subcategory.subcategoryId"
                >
                    {{ subcategory.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <app-month-picker
            class="field"
            label="Fecha de Inicio"
            (momentSelected)="minDate = $event; rangeChanged()"
        ></app-month-picker>
        <app-month-picker
            class="field"
            label="Fecha Final"
            (momentSelected)="maxDate = $event; rangeChanged()"
            [disabled]="minDate === null"
            [minValue]="getDate(minDate)"
        ></app-month-picker>
        <mat-form-field color="accent">
            <mat-label>Titulo del Reporte</mat-label>
            <input matInput [(ngModel)]="title" />
        </mat-form-field>
        <button class="print_button" mat-raised-button (click)="print()">
            <mat-icon>print</mat-icon>Imprimir
        </button>
    </div>
</mat-card>
<h5 class="media-only title">{{ title }}</h5>
<mat-card>
    <table mat-table [dataSource]="filteredTableData" class="table3">
        <!-- Dynamic columns -->
        <ng-container *ngFor="let column of columns" [matColumnDef]="column">
            <th mat-header-cell class="currency" *matHeaderCellDef>
                {{ formatColumn(column) }}
            </th>
            <td
                mat-cell
                *matCellDef="let item"
                [attr.colspan]="item.colspan || 1"
                [class]="getDynamicClass(item[column], item.style)"
            >
                {{ formatValue(item[column]) }}
            </td>
        </ng-container>

        <!-- Table rows -->
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns"></tr>
    </table>
</mat-card>
