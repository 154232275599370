import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-event-edit-add-dialog',
  templateUrl: './event-edit-add-dialog.component.html',
  styleUrls: ['./event-edit-add-dialog.component.scss']
})
export class EventEditAddDialogComponent {
  EventAction = EventAction
  constructor(
    public dialogRef: MatDialogRef<EventEditAddDialogComponent>
) {}

response(action: EventAction): void {
    this.dialogRef.close(action)
}

}

export enum EventAction {
  EDIT,
  NEW
}
