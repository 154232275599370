import { Injectable } from '@angular/core'
import { CanActivate } from '@angular/router'
import { CookieService } from 'ngx-cookie-service'
import { COOKIE_ROLE } from '../../const/constants'
import { Roles } from '../../enums/roles'

@Injectable({
    providedIn: 'root',
})
export class RoleGuardService implements CanActivate {
    constructor(private cookieService: CookieService) {}
    canActivate(): boolean {
        let role = this.cookieService.get(COOKIE_ROLE)
        return role === Roles.Administrator
    }
}
