import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuardService } from './core/services/util/auth-guard.service'
import { TicketComponent } from './ui/components/util/ticket/ticket.component'
import { CategoriesComponent } from './ui/pages/categories/categories.component'
import { DashboardComponent } from './ui/pages/dashboard/dashboard.component'
import { EgresosComponent } from './ui/pages/egresos/egresos.component'
import { HomePageComponent } from './ui/pages/home-page/home-page.component'
import { IngresosPageComponent } from './ui/pages/ingresos-page/ingresos-page.component'
import { LoginComponent } from './ui/pages/login/login.component'
import { PettyComponent } from './ui/pages/petty/petty.component'
import { CashFlowSimpleComponent } from './ui/pages/reports/cash-flow-simple/cash-flow-simple.component'
import { CashFlowComponent } from './ui/pages/reports/cash-flow/cash-flow.component'
import { DailyCutComponent } from './ui/pages/reports/daily-cut/daily-cut.component'
import { MensualBalanceComponent } from './ui/pages/reports/mensual-balance/mensual-balance.component'
import { SubcategoriesComponent } from './ui/pages/subcategories/subcategories.component'
import { LocationsComponent } from './ui/pages/locations/locations.component'
import { RoleGuardService } from './core/services/util/role-guard.service'
import { DailyCutRestrictedComponent } from './ui/pages/reports/daily-cut-restricted/daily-cut-restricted.component'
import { ComparisonComponent } from './ui/pages/reports/comparison/comparison.component'
import { CalendarComponent } from './ui/pages/calendar/calendar.component'
import { CalendarGuardService } from './core/services/util/calendar-guard.service'

const routes: Routes = [
    { path: 'login', component: LoginComponent },
    {
        path: '',
        component: DashboardComponent,
        canActivate: [AuthGuardService],
        children: [
            { path: '', component: HomePageComponent },
            { path: 'ingresos', component: IngresosPageComponent },
            { path: 'egresos', component: EgresosComponent },
            { path: 'corte-caja', component: DailyCutRestrictedComponent },
            {
                path: 'corte-diario',
                component: DailyCutComponent,
                canActivate: [RoleGuardService],
            },
            {
                path: 'reporte-mensual',
                component: MensualBalanceComponent,
                canActivate: [RoleGuardService],
            },
            {
                path: 'flujo-efectivo',
                component: CashFlowComponent,
                canActivate: [RoleGuardService],
            },
            {
                path: 'flujo-efectivo-simple',
                component: CashFlowSimpleComponent,
                canActivate: [RoleGuardService],
            },
            {
                path: 'comparativos',
                component: ComparisonComponent,
                canActivate: [RoleGuardService],
            },
            {
                path: 'calendario',
                component: CalendarComponent,
                canActivate: [CalendarGuardService],
            },
            { path: 'cantidad-entregada', component: PettyComponent },
        ],
    },
    {
        path: 'admin',
        component: DashboardComponent,
        canActivate: [AuthGuardService, RoleGuardService],
        children: [
            { path: 'categories', component: CategoriesComponent },
            { path: 'subcategories', component: SubcategoriesComponent },
            { path: 'ubicaciones', component: LocationsComponent },
        ],
    },
    { path: 'receipt', component: TicketComponent },
    { path: 'recibo/:id', component: TicketComponent },
]

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
