<div class="top-form no-media">
    <div class="colors">
        <p *ngFor="let category of categories; let i = index">
            <span [class]="'color' + i"></span
            ><label>{{ category.name }}</label>
        </p>
    </div>
    <div class="buttons">
        <button mat-button (click)="addIncome()">
            <mat-icon>add</mat-icon>Agregar Ingreso
        </button>
        <button mat-button (click)="printEvents()">
            <mat-icon>download</mat-icon>Descargar Eventos
        </button>
    </div>
</div>
<div class="calendar-container no-media">
    <div class="row text-center">
        <div class="col-md-4">
            <div class="btn-group">
                <button
                    color="accent"
                    mat-button
                    class="navigation-button-dark"
                    mwlCalendarPreviousView
                    [view]="view"
                    [(viewDate)]="viewDate"
                    (viewDateChange)="closeOpenMonthViewDay()"
                >
                    Anterior
                </button>
                <button
                    mat-button
                    class="navigation-button-light"
                    mwlCalendarToday
                    [(viewDate)]="viewDate"
                >
                    Hoy
                </button>
                <button
                    mat-button
                    class="navigation-button-dark"
                    mwlCalendarNextView
                    [view]="view"
                    [(viewDate)]="viewDate"
                    (viewDateChange)="closeOpenMonthViewDay()"
                >
                    Siguiente
                </button>
            </div>
        </div>
        <div class="col-md-4">
            <h3 class="title">
                {{ viewDate | calendarDate: view + 'ViewTitle':'es' }}
            </h3>
        </div>
        <div class="col-md-4">
            <div class="btn-group">
                <button
                    mat-button
                    class="navigation-button-dark"
                    (click)="setView(CalendarView.Month, 0)"
                    [class.active]="view === CalendarView.Month"
                >
                    Mes
                </button>
                <button
                    mat-button
                    class="navigation-button-dark"
                    (click)="setView(CalendarView.Week, 1)"
                    [class.active]="view === CalendarView.Week"
                >
                    Semana
                </button>
                <button
                    mat-button
                    class="navigation-button-dark"
                    (click)="setView(CalendarView.Day, 2)"
                    [class.active]="view === CalendarView.Day"
                >
                    Día
                </button>
            </div>
        </div>
    </div>
    <br />
    <div [ngSwitch]="view">
        <mwl-calendar-month-view
            *ngSwitchCase="CalendarView.Month"
            [viewDate]="viewDate"
            [events]="events"
            [locale]="locale"
            [refresh]="refresh"
            [activeDayIsOpen]="activeDayIsOpen"
            (dayClicked)="dayClicked($event.day)"
            (eventClicked)="handleEvent('Clicked', $event.event)"
            (eventTimesChanged)="eventTimesChanged($event)"
        >
        </mwl-calendar-month-view>
        <mwl-calendar-week-view
            *ngSwitchCase="CalendarView.Week"
            [viewDate]="viewDate"
            [events]="events"
            [locale]="locale"
            [refresh]="refresh"
            [dayStartHour]="7"
            [dayStartMinute]="0"
            [dayEndHour]="23"
            [dayEndMinute]="59"
            (hourSegmentClicked)="hourClicked($event)"
            (eventClicked)="handleEvent('Clicked', $event.event)"
            (eventTimesChanged)="eventTimesChanged($event)"
        >
        </mwl-calendar-week-view>
        <mwl-calendar-day-view
            *ngSwitchCase="CalendarView.Day"
            [viewDate]="viewDate"
            [events]="events"
            [locale]="locale"
            [refresh]="refresh"
            [dayStartHour]="7"
            [dayStartMinute]="0"
            [dayEndHour]="23"
            [dayEndMinute]="59"
            (eventClicked)="handleEvent('Clicked', $event.event)"
            (eventTimesChanged)="eventTimesChanged($event)"
        >
        </mwl-calendar-day-view>
    </div>
</div>
<div class="print-table">
    <div *ngFor="let event of printEventList">
        <h6>
            {{ event.eventDateStart | date: 'dd/MM/yyyy' }},{{
                event.eventTimeStart
            }}
            - {{ event.eventDateEnd | date: 'dd/MM/yyyy' }},
            {{ event.eventTimeEnd }} - {{ event.location }}
        </h6>
        <p>{{ event.description }}</p>
    </div>
    <!--<table mat-table [dataSource]="eventsTable">
        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>Fecha</th>
            <td mat-cell *matCellDef="let element; let i = index">
                <ng-container
                    *ngIf="
                        i === 0 || element.date !== eventsTable[i - 1]['date']
                    "
                >
                    {{ element.date }}
                </ng-container>
            </td>
        </ng-container>
        <ng-container matColumnDef="time">
            <th mat-header-cell *matHeaderCellDef>Hora</th>
            <td mat-cell *matCellDef="let element">{{ element.time }}</td>
        </ng-container>
        <ng-container
            *ngFor="let location of locations"
            [matColumnDef]="location.locationId + ''"
        >
            <th mat-header-cell *matHeaderCellDef>{{ location.name }}</th>
            <td mat-cell *matCellDef="let element">
                {{ element[location.locationId + ''] }}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>-->
</div>
