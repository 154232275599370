import {
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewEncapsulation,
} from '@angular/core'
import { LoadingService } from './core/services/util/loading.service'
import { ConfigurationService } from './core/services/api/configuration.service'
import { ConfigurationItem } from './core/interfaces/configuration-item'
import { firstValueFrom } from 'rxjs'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    title = 'ecclesia-frontend'
    private _loading = false
    private _date = new Date()
    get loading() {
        return this._loading
    }
    get date() {
        return this._date
    }

    constructor(
        private loadingService: LoadingService,
        private changeDetRef: ChangeDetectorRef,
        private configurationService:ConfigurationService
    ) {
    }
    ngOnInit(): void {
        this.getConfig()
        this.loadingService.loading.subscribe((loading) => {
            this._loading = loading
            this.changeDetRef.detectChanges()
        })        
    }
    async getConfig(){
        await this.configurationService.retrieve()
    }
}
