<div *ngIf="loading" class="spinner">
    <mat-spinner diameter="60" color="#ccc"></mat-spinner>
</div>
<!--<div class="print-only">
    <div>
        <p>
            {{ date.toLocaleString('en-Gb') }}
        </p>
        <p>Ecclesia</p>
    </div>
</div>-->

<router-outlet></router-outlet>
