import { Component, Inject, OnInit } from '@angular/core'
import {
    AbstractControl,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
    selector: 'app-add-petty',
    templateUrl: './add-petty.component.html',
    styleUrls: ['./add-petty.component.scss'],
})
export class AddPettyComponent implements OnInit {
    formGroup!: UntypedFormGroup
    formControl!: AbstractControl | null
    constructor(
        public dialogRef: MatDialogRef<AddPettyComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit(): void {
        this.formGroup = new UntypedFormGroup({
            Amount: new UntypedFormControl(this.data?.quantity, [
                Validators.required,
            ]),
            Notes: new UntypedFormControl(this.data?.notes),
        })
        this.formControl = this.formGroup.get('Amount')
    }
    getErrorMessage() {
        return this.formControl?.hasError('required') ? 'Campo requerido' : ''
    }
    onNoClick(): void {
        this.dialogRef.close()
    }

    public confirmAdd(): void {
        console.log(this.formGroup.value)
        this.dialogRef.close(this.formGroup.value)
    }
}
