<h2 class="media-only title">{{ title }}</h2>
<mat-card class="no-media">
    <div class="form form-top">
        <h2>Filtros</h2>
        <div class="filtros">
            <mat-form-field appearance="fill">
                <mat-label>Escoge una fecha</mat-label>
                <input
                    matInput
                    [max]="maxDate"
                    [matDatepicker]="picker"
                    [(ngModel)]="date"
                    (dateChange)="dateChanged($event)"
                />
                <mat-hint>DD/MM/YYYY</mat-hint>
                <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field color="accent" class="title">
                <input
                    matInput
                    class="form-control"
                    placeholder="Titulo del Reporte"
                    [(ngModel)]="title"
                />
            </mat-form-field>
        </div>

        <button class="print_button" mat-raised-button (click)="print()">
            <mat-icon>print</mat-icon>Imprimir
        </button>
    </div>
</mat-card>
<h4 class="media-only balance-title">
    Saldo del día anterior {{ previousBalance | currency }}
</h4>
<div class="container table3 mat-elevation-z8 page">
    <h2>Ingresos</h2>
    <table mat-table [dataSource]="dataSourceIncome">
        <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef>Categoria</th>
            <td mat-cell *matCellDef="let element" [class]="element.style">
                {{ element.category }}
            </td>
            <td mat-footer-cell *matFooterCellDef class="category">Total:</td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef class="table_description">
                Descripcion
            </th>
            <td mat-cell *matCellDef="let element" [class]="element.style">
                {{ element.title }}
            </td>
            <td mat-footer-cell *matFooterCellDef class="category"></td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="ticket">
            <th mat-header-cell *matHeaderCellDef>Recibo</th>
            <td mat-cell *matCellDef="let element" [class]="element.style">
                {{ element.ticket }}
            </td>
            <td mat-footer-cell *matFooterCellDef class="category"></td>
        </ng-container>

        <ng-container matColumnDef="amount">
            <th class="quantity" mat-header-cell *matHeaderCellDef>Cantidad</th>
            <td
                class="quantity"
                mat-cell
                *matCellDef="let element"
                [class]="element.style"
            >
                <label *ngIf="element.amount !== null" class="label-ingreso"
                    ><span>$</span>{{ element.amount | currency: '':'' }}</label
                >
            </td>
            <td
                mat-footer-cell
                *matFooterCellDef
                class="category quantity"
            ></td>
        </ng-container>
        <ng-container matColumnDef="total">
            <th class="quantity" mat-header-cell *matHeaderCellDef>Total</th>
            <td
                class="quantity"
                mat-cell
                *matCellDef="let element"
                [class]="element.style"
            >
                <label
                    *ngIf="
                        element.total !== null && element.total !== undefined
                    "
                    class="label-ingreso"
                    ><span>$</span>{{ element.total | currency: '':'' }}</label
                >
            </td>
            <td mat-footer-cell *matFooterCellDef class="category quantity">
                <label class="label-ingreso"
                    ><span>$</span>{{ income | currency: '':'' }}</label
                >
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </table>
</div>

<div class="container table3 mat-elevation-z8 page">
    <h2>Egresos</h2>
    <table mat-table [dataSource]="dataSourceOutcome">
        <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef>Categoria</th>
            <td mat-cell *matCellDef="let element" [class]="element.style">
                {{ element.category }}
            </td>
            <td mat-footer-cell *matFooterCellDef class="category">Total:</td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef class="table_description">
                Descripcion
            </th>
            <td mat-cell *matCellDef="let element" [class]="element.style">
                {{ element.title }}
            </td>
            <td mat-footer-cell *matFooterCellDef class="category"></td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="ticket">
            <th mat-header-cell *matHeaderCellDef>Recibo</th>
            <td mat-cell *matCellDef="let element" [class]="element.style">
                {{ element.ticket }}
            </td>
            <td mat-footer-cell *matFooterCellDef class="category"></td>
        </ng-container>

        <ng-container matColumnDef="amount">
            <th class="quantity" mat-header-cell *matHeaderCellDef>Cantidad</th>
            <td
                class="quantity"
                mat-cell
                *matCellDef="let element"
                [class]="element.style"
            >
                <label *ngIf="element.amount !== null" class="label-egreso"
                    ><span>$</span>{{ element.amount | currency: '':'' }}</label
                >
            </td>
            <td
                mat-footer-cell
                *matFooterCellDef
                class="category quantity"
            ></td>
        </ng-container>

        <ng-container matColumnDef="total">
            <th class="quantity" mat-header-cell *matHeaderCellDef>Total</th>
            <td
                class="quantity"
                mat-cell
                *matCellDef="let element"
                [class]="element.style"
            >
                <label
                    *ngIf="
                        element.total !== null && element.total !== undefined
                    "
                    class="label-egreso"
                    ><span>$</span>{{ element.total | currency: '':'' }}</label
                >
            </td>
            <td mat-footer-cell *matFooterCellDef class="category quantity">
                <label class="label-egreso"
                    ><span>$</span>{{ outcome | currency: '':'' }}</label
                >
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </table>
</div>

<div class="totals-table table3 mat-elevation-z8">
    <table mat-table [dataSource]="dataSourceTotal">
        <!-- Title Column -->
        <ng-container matColumnDef="titles">
            <th class="title-total" mat-header-cell *matHeaderCellDef>
                Resumen corte diario
            </th>
            <td mat-cell *matCellDef="let element">
                {{ element.title }}
            </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="total">
            <th class="quantity" mat-header-cell *matHeaderCellDef></th>
            <td class="quantity" mat-cell *matCellDef="let element">
                <label
                    [ngClass]="{
                        'label-ingreso':
                            element.style === 'label-ingreso' ||
                            (element.style === 'category' &&
                                element.quantity > 0),
                        'label-egreso':
                            element.style === 'label-egreso' ||
                            (element.style === 'category' &&
                                element.quantity < 0),
                        'category-print': element.style === 'category'
                    }"
                    ><span>$</span
                    >{{ element.quantity | currency: '':'' }}</label
                >
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['titles', 'total']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['titles', 'total']"></tr>
    </table>
</div>
<div class="totals-table table3 mat-elevation-z8 page">
    <table mat-table [dataSource]="dataSourceGlobal">
        <ng-container matColumnDef="titles">
            <th class="title-total" mat-header-cell *matHeaderCellDef>
                Saldo global
            </th>
            <td mat-cell *matCellDef="let element">
                {{ element.title }}
            </td>
        </ng-container>

        <ng-container matColumnDef="total">
            <th class="quantity" mat-header-cell *matHeaderCellDef></th>
            <td class="quantity" mat-cell *matCellDef="let element">
                <label
                    [ngClass]="{
                        'label-ingreso':
                            element.style === 'label-ingreso' ||
                            (element.style === 'category' &&
                                element.quantity > 0),
                        'label-egreso':
                            element.style === 'label-egreso' ||
                            (element.style === 'category' &&
                                element.quantity < 0),
                        'category-print': element.style === 'category'
                    }"
                    ><span>$</span
                    >{{ element.quantity | currency: '':'' }}</label
                >
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['titles', 'total']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['titles', 'total']"></tr>
    </table>
</div>
