import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
} from '@angular/core'
import { ICategory } from 'src/app/core/interfaces/Category'
import { IRecord } from 'src/app/core/interfaces/record'
import { ISubcategory } from 'src/app/core/interfaces/subcategory'
import { CategoriesService } from 'src/app/core/services/api/categories.service'
import { RecordsService } from 'src/app/core/services/api/records.service'
import { SubcategoriesService } from 'src/app/core/services/api/subcategories.service'
import { LoadingService } from 'src/app/core/services/util/loading.service'
import { AddRecordComponent } from '../../components/dialogs/add-record/add-record.component'
import {
    ConfirmationData,
    ConfirmationComponent,
} from '../../components/dialogs/confirmation/confirmation.component'
import { TableUtil } from '../../components/util/table-util'
import { DatePipe } from '@angular/common'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { COOKIE_ROLE, RECEIPT_DATA } from 'src/app/core/const/constants'
import { IReceipt } from 'src/app/core/interfaces/receipt'
import { CookieService } from 'ngx-cookie-service'
import { MatTable, MatTableDataSource } from '@angular/material/table'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'

@Component({
    selector: 'app-egresos',
    templateUrl: './egresos.component.html',
    styleUrls: ['./egresos.component.scss'],
})
export class EgresosComponent implements OnInit, AfterViewInit {
    private _categories: ICategory[] = []
    private _subcategories: ISubcategory[] = []
    private _allSubcategories: ISubcategory[] = []
    pageSize = 10
    displayedColumns = [
        'date',
        'ticket',
        'description',
        'payee',
        'category',
        'subcategory',
        'amount',
        'actions',
    ]
    dataSource = new MatTableDataSource<IRecord>()
    private _expenses: IRecord[] = []
    private _filter: string = ''
    private _role: string = ''
    categoryFilter = -1
    subcategoryFilter = -1
    range = new UntypedFormGroup({
        start: new UntypedFormControl(null),
        end: new UntypedFormControl(null),
    })
    get filter() {
        return this._filter
    }
    set filter(filter: string) {
        this._filter = filter
    }
    get categories() {
        return this._categories
    }
    get subcategories() {
        return this._subcategories
    }
    get fromDate() {
        return this.range?.get('start')?.value
    }
    get toDate() {
        return this.range?.get('end')?.value
    }
    get role() {
        return this._role
    }
    constructor(
        private recordService: RecordsService,
        private categoryService: CategoriesService,
        private subcaregoryService: SubcategoriesService,
        private loadingService: LoadingService,
        private cookieService: CookieService,
        public dialog: MatDialog,
        private changeDetRef: ChangeDetectorRef,
        public datePipe: DatePipe
    ) {
        this.dataSource.filterPredicate = (data, filter) => {
            const a =
                this.categoryFilter < 0 ||
                data.categoryId == this.categoryFilter
            const b =
                this.subcategoryFilter < 0 ||
                data.subcategoryId == this.subcategoryFilter
            let date = new Date(data.dateCreated)
            const c =
                !(this.fromDate && this.toDate) ||
                (date >= this.fromDate && date <= this.toDate)
            const d =
                this.filter.length === 0 ||
                data.notes
                    .toLowerCase()
                    .includes(this.filter.toLocaleLowerCase()) ||
                data.payee
                    .toLowerCase()
                    .includes(this.filter.toLocaleLowerCase())

            return a && b && c && d
        }
    }
    @ViewChild(MatTable, { static: true }) table!: ElementRef
    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator
    ngOnInit(): void {
        this.categoryService.categories_expenses.subscribe((categories) => {
            this._categories = categories
        })
        this.subcaregoryService.subcategories.subscribe((subcategories) => {
            this._allSubcategories = subcategories
        })
        this.refresh()
        this._role = this.cookieService.get(COOKIE_ROLE)
    }
    customFilter() {
        if (this.categoryFilter != -1) {
            this.getSubcategories()
        } else {
            this._subcategories = []
        }
        this.dataSource.filter = '' + Math.random()
    }
    private getSubcategories() {
        this.categoryService
            .getById(this.categoryFilter)
            .subscribe((category) => {
                this._subcategories = category.subcategories.sort((a, b) =>
                    a.name.localeCompare(b.name)
                )
            })
    }

    resetRange() {
        this.range?.get('start')?.patchValue('')
        this.range?.get('end')?.patchValue('')
        this.customFilter()
    }
    getCategoryName(id: number) {
        return this._categories.find((category) => category.categoryId === id)
            ?.name
    }
    getSubcategoryName(id: number) {
        let subcat =
            this.subcategories.length == 0
                ? this._allSubcategories
                : this.subcategories
        return subcat.find((subcategory) => subcategory.subcategoryId == id)
            ?.name
    }
    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator
    }
    loadData() {
        this.recordService.expenses.subscribe((expenses) => {
            this._expenses = expenses
            this.dataSource.data = expenses
            this.changeDetRef.detectChanges()
        })
    }
    refresh() {
        this.recordService.updateData()
        this.loadData()
    }

    addNew() {
        const dialogRef = this.dialog.open(AddRecordComponent, {
            width: '600px',
            data: {
                title: 'Nuevo egreso',
                recordTypeId: 2,
                categories: this._categories,
                subcategories: this._subcategories,
                isEdit: false,
            },
        })

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.recordService.post(result).subscribe((response) => {
                    this.refresh()
                })
            }
        })
    }
    applyFilter() {
        const filterValue = this._filter
        this.dataSource.filter = filterValue.trim().toLowerCase()
    }
    startEdit(id: number, outcome: IRecord) {
        const dialogRef = this.dialog.open(AddRecordComponent, {
            data: {
                title: 'Editar registro',
                id: id,
                record: outcome,
                recordTypeId: 1,
                categories: this._categories,
                subcategories: this._subcategories,
                isEdit: true,
            },
        })

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.loadingService.show()
                this.recordService.put(result, id).subscribe((response) => {
                    this.loadingService.hide()
                    this.refresh()
                })
            }
        })
    }
    deleteItem(id: number) {
        const data = new ConfirmationData()
        data.description = 'Estas seguro que deseas eliminar la categoria?'
        const dialogRef = this.dialog.open(ConfirmationComponent, {
            data: data,
        })

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.loadingService.show()
                console.log(id)
                this.recordService.delete(id).subscribe(() => {
                    this.loadingService.hide()
                    this.refresh()
                })
            }
        })
    }
    sum() {
        var total = 0
        let data = this.dataSource.filteredData as IRecord[]
        data.forEach((obj: IRecord) => {
            total += obj.amount
        })
        return total
    }

    exportTable() {
        let data = this.dataSource.filteredData as IRecord[]
        const parsedData: Partial<any>[] = data.map((x) => ({
            Recibo: x.ticketNo,
            Solicitante: x.payee,
            Categoria: this.getCategoryName(x.categoryId),
            Subcategoria: this.getSubcategoryName(x.subcategoryId),
            Cantidad: x.amount,
            Notas: x.notes,
            Fecha: this.datePipe.transform(x.dateCreated, 'dd/MM/yyyy'),
        }))
        let currentDate = this.datePipe.transform(
            new Date(),
            'dd.MM.yyyy-hh.mm'
        )
        TableUtil.exportArrayToExcel(parsedData, 'Egresos' + currentDate)
    }

    showTicket(income: IRecord) {
        let receiptData: IReceipt = {
            title: '',
            ticketNo: income.ticketNo,
            category: this.getCategoryName(income.categoryId) ?? '',
            subcategory: this.getSubcategoryName(income.subcategoryId) ?? '',
            payee: income.payee,
            amount: income.amount,
            notes: income.notes,
            date: income.dateCreated,
            location: undefined,
        }
        localStorage.setItem(
            `${RECEIPT_DATA}${income.ticketNo}`,
            JSON.stringify(receiptData)
        )
        //console.log(JSON.stringify(income))
        /*window.open(
            `${window.location.origin}/ecclesia/#/receipt?ticket=${income.ticketNo}`,
            '_blank'
        )*/
        //local url
        window.open(`${window.location.origin}/#/recibo/${income.id}`, '_blank')
    }
    /*getLocationName(locationId: number) {
        return this._locations.find(
            (location) => location.locationId === locationId
        )?.name
    }*/
}
