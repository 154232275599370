import { Component, OnInit } from '@angular/core'
import { AuthService } from 'src/app/core/services/api/auth-service.service'

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
    user = ''
    constructor(private authService: AuthService) {}

    ngOnInit() {
        let storedUser = sessionStorage.getItem('user')
        this.user = storedUser != null ? storedUser : 'e-cclesia'
    }

    closeSession() {
        this.authService.logout()
    }
}
