import { DatePipe } from '@angular/common'
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { MatSort } from '@angular/material/sort'
import { CONFIGURATION_ITEMS, COOKIE_ROLE } from 'src/app/core/const/constants'
import { ICategory } from 'src/app/core/interfaces/Category'
import { IRecord } from 'src/app/core/interfaces/record'
import { ISubcategory } from 'src/app/core/interfaces/subcategory'
import { CategoriesService } from 'src/app/core/services/api/categories.service'
import { RecordsService } from 'src/app/core/services/api/records.service'
import { SubcategoriesService } from 'src/app/core/services/api/subcategories.service'
import { LoadingService } from 'src/app/core/services/util/loading.service'
import {
    ConfirmationComponent,
    ConfirmationData,
} from '../../components/dialogs/confirmation/confirmation.component'
import { TableUtil } from '../../components/util/table-util'
import { LocationsService } from 'src/app/core/services/api/locations.service'
import { ILocation } from 'src/app/core/interfaces/location'
import { MatTableDataSource } from '@angular/material/table'
import { CookieService } from 'ngx-cookie-service'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { ViewEncapsulation } from '@angular/core'
import { MatTabGroup } from '@angular/material/tabs'
import { IEventData } from '../../components/events/events.component'
import { AddIncomeEventWizardComponent } from '../../components/dialogs/add-income-event-wizard/add-income-event-wizard.component'
import { AddRecordComponent } from '../../components/dialogs/add-record/add-record.component'
import { ConfigurationService } from 'src/app/core/services/api/configuration.service'

@Component({
    selector: 'app-ingresos-page',
    templateUrl: './ingresos-page.component.html',
    styleUrls: ['./ingresos-page.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class IngresosPageComponent implements OnInit, AfterViewInit {
    private _title = ''
    private _categories: ICategory[] = []
    private _subcategories: ISubcategory[] = []
    private _allSubcategories: ISubcategory[] = []
    private _calendarEnabled = false
    private _eventData: IEventData = {
        recordId: -1,
        recordTitle: '',
    }
    animationState!: string
    displayedColumns = [
        'date',
        'ticket',
        'payee',
        'description',
        'category',
        'subcategory',
        'location',
        'amount',
        'actions',
    ]
    dataSource = new MatTableDataSource<IRecord>()
    private _income: IRecord[] = []
    private _filter: string = ''
    private _role: string = ''
    private _locations: ILocation[] = []
    categoryFilter = -1
    subcategoryFilter = -1
    range = new UntypedFormGroup({
        start: new UntypedFormControl(null),
        end: new UntypedFormControl(null),
    })

    get filter() {
        return this._filter
    }
    set filter(filter: string) {
        this._filter = filter
    }
    get categories() {
        return this._categories
    }
    get subcategories() {
        return this._subcategories
    }

    get fromDate() {
        return this.range?.get('start')?.value
    }
    get toDate() {
        return this.range?.get('end')?.value
    }
    get role() {
        return this._role
    }
    get eventData() {
        return this._eventData
    }
    get calendarEnabled() {
        return this._calendarEnabled
    }
    @ViewChild('myTabGroup') myTabGroup!: MatTabGroup

    constructor(
        private recordService: RecordsService,
        private categoryService: CategoriesService,
        private subcaregoryService: SubcategoriesService,
        private locationsService: LocationsService,
        private loadingService: LoadingService,
        private cookieService: CookieService,
        private configurationService: ConfigurationService,
        public dialog: MatDialog,
        private changeDetRef: ChangeDetectorRef,
        public datePipe: DatePipe
    ) {
        this.datePipe = new DatePipe('en')
        this.dataSource.filterPredicate = (data, filter) => {
            const a =
                this.categoryFilter < 0 ||
                data.categoryId == this.categoryFilter
            const b =
                this.subcategoryFilter < 0 ||
                data.subcategoryId == this.subcategoryFilter
            let date = new Date(data.dateCreated)
            const c =
                !(this.fromDate && this.toDate) ||
                (date >= this.fromDate && date <= this.toDate)
            const d =
                this.filter.length === 0 ||
                data.notes
                    .toLowerCase()
                    .includes(this.filter.toLocaleLowerCase()) ||
                data.payee
                    .toLowerCase()
                    .includes(this.filter.toLocaleLowerCase())

            return a && b && c && d
        }
    }

    @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator
    @ViewChild(MatSort) sort!: MatSort
    ngOnInit(): void {
        const domain = window.location.host
        this._title = domain
        this._calendarEnabled = this.configurationService.retrieveByName(CONFIGURATION_ITEMS.FFCalendar)?.value == 'true'
        this._title = this._title.split('.')[0].replace('-', ' ')
        this._title = this.capitalizeWords(this._title)
        this.categoryService.categories_income.subscribe((categories) => {
            this._categories = categories
        })
        this.subcaregoryService.subcategories.subscribe((subcategories) => {
            this._allSubcategories = subcategories
        })
        this.locationsService.get().subscribe((locations) => {
            this._locations = locations
        })
        this.refresh()
        this._role = this.cookieService.get(COOKIE_ROLE)
    }
    private capitalizeWords(str: string): string {
        const words = str.split(' ')
        const capitalizedWords = words.map((word) => {
            return word.charAt(0).toUpperCase() + word.slice(1)
        })
        return capitalizedWords.join(' ')
    }
    getCategoryName(id: number) {
        return this._categories.find((category) => category.categoryId === id)
            ?.name
    }
    getSubcategoryName(id: number) {
        let subcat =
            this.subcategories.length == 0
                ? this._allSubcategories
                : this.subcategories
        return subcat.find((subcategory) => subcategory.subcategoryId === id)
            ?.name
    }
    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator
        this.dataSource.sort = this.sort
    }
    resetRange() {
        this.range?.get('start')?.patchValue('')
        this.range?.get('end')?.patchValue('')
        this.customFilter()
    }
    loadData() {
        this.recordService.income.subscribe((income) => {
            this._income = income
            this.dataSource.data = income
            this.changeDetRef.detectChanges()
        })
    }
    refresh() {
        this.categoryService.updateData()
        this.subcaregoryService.updateData()
        this.recordService.updateData()
        this.loadData()
        this.paginator._changePageSize(this.paginator.pageSize)
    }
    showTicket(income: IRecord) {
        window.open(`${window.location.origin}/#/recibo/${income.id}`, '_blank')
    }
    addNew() {
        if(this.calendarEnabled){
            const dialogRef = this.dialog.open(AddIncomeEventWizardComponent, {
                width: '100%',
                data: {
                    title: 'Nuevo ingreso',
                    recordTypeId: 1,
                    categories: this._categories,
                    subcategories: this._subcategories,
                    isEdit: false,
                    locations: this._locations,
                    catetegories: this._categories,
                },
            })
    
            dialogRef.afterClosed().subscribe((result) => {
                this.refresh()
            })
        } else{
            const dialogRef = this.dialog.open(AddRecordComponent, {
                width: '600px',
                data: {
                    title: 'Nuevo ingreso',
                    recordTypeId: 1,
                    categories: this._categories,
                    subcategories: this._subcategories,
                    isEdit: false,
                },
            })
    
            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.recordService.post(result).subscribe((response) => {
                        this.refresh()
                    })
                }
            })
        }
        
    }
    customFilter() {
        if (this.categoryFilter != -1) {
            this.getSubcategories()
        } else {
            this._subcategories = []
        }
        this.dataSource.filter = '' + Math.random()
    }
    private getSubcategories() {
        this.categoryService
            .getById(this.categoryFilter)
            .subscribe((category) => {
                this._subcategories = category.subcategories.sort((a, b) =>
                    a.name.localeCompare(b.name)
                )
            })
    }
    startEdit(id: number, income: IRecord) {
        if(this.calendarEnabled){     
            const dialogRef = this.dialog.open(AddIncomeEventWizardComponent, {
                width: '95vw',
                maxWidth: '95vw',
                data: {
                    title: 'Editar ingreso',
                    record: income,
                    recordTypeId: 1,
                    categories: this._categories,
                    subcategories: this._subcategories,
                    isEdit: false,
                    locations: this._locations,
                    catetegories: this._categories,
                },
            })

            dialogRef.afterClosed().subscribe((result) => {
                this.refresh()
            })
        } else{
            const dialogRef = this.dialog.open(AddRecordComponent, {
                data: {
                    title: 'Editar registro',
                    id: id,
                    record: income,
                    recordTypeId: 1,
                    categories: this._categories,
                    subcategories: this._subcategories,
                    isEdit: true,
                },
            })
    
            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.loadingService.show()
                    this.recordService.put(result, id).subscribe((response) => {
                        this.loadingService.hide()
                        this.refresh()
                    })
                }
            })
        }
    }
    deleteItem(id: number) {
        const data = new ConfirmationData()
        data.description = 'Estas seguro que deseas eliminar el ingreso?'
        const dialogRef = this.dialog.open(ConfirmationComponent, {
            data: data,
        })

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.loadingService.show()
                console.log(id)
                this.recordService.delete(id).subscribe(() => {
                    this.loadingService.hide()
                    this.refresh()
                })
            }
        })
    }
    sum() {
        var total = 0
        let data = this.dataSource.filteredData as IRecord[]
        data.forEach((obj: IRecord) => {
            total += obj.amount
        })
        return total
    }

    exportTable() {
        let data = this.dataSource.filteredData as IRecord[]
        const parsedData: Partial<any>[] = data.map((x) => ({
            Recibo: x.ticketNo,
            Solicitante: x.payee,
            Categoria: this.getCategoryName(x.categoryId),
            Subcategoria: this.getSubcategoryName(x.subcategoryId),
            Cantidad: x.amount,
            Notas: x.notes,
            Fecha: this.datePipe.transform(x.dateCreated, 'dd/MM/yyyy'),
        }))
        let currentDate = this.datePipe.transform(
            new Date(),
            'dd.MM.yyyy-hh.mm'
        )
        TableUtil.exportArrayToExcel(parsedData, 'Ingresos' + currentDate)
    }

    getLocationName(locationId: number) {
        return this._locations.find(
            (location) => location.locationId === locationId
        )?.name
    }

    getEvents(recordId: number, recordTitle: string) {
        this._eventData = {
            recordId: recordId,
            recordTitle: recordTitle,
        }
        this.changeTab(1)
        /*this.dialog.open(EventsDialogComponent, {
            data: 
            {
                recordId: recordId,
                recordTitle: recordTitle
            },            
            panelClass: 'custom-dialog-container',
            width: '100vw',
            height:'100vh',
        })*/
    }
    changeTab(index: number) {
        this.myTabGroup.selectedIndex = index
    }
}
