import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core'
import { FormControl } from '@angular/forms'
import {
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    MomentDateAdapter,
} from '@angular/material-moment-adapter'
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
} from '@angular/material/core'
import { MatDatepicker } from '@angular/material/datepicker'
import * as _moment from 'moment'
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment'

const moment = _rollupMoment || _moment

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
}

@Component({
    selector: 'app-month-picker',
    templateUrl: './month-picker.component.html',
    styleUrls: ['./month-picker.component.scss'],
    providers: [
        // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
        // application's root module. We provide it at the component level here, due to limitations of
        // our example generation script.
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },

        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
})
export class MonthPickerComponent {
    private _date = new FormControl()
    private _maxDate = new Date()
    get date() {
        return this._date
    }
    get maxDate() {
        return this._maxDate
    }
    @Input() dateInput!: Moment | null
    @Input() label: string = 'Month and Year'
    @Input() minValue: Date = new Date('01/01/2020')
    @Input() disabled: boolean = false
    @Output() momentSelected = new EventEmitter()
    constructor() {}

    getMoment(
        normalizedMonthAndYear: Moment,
        datepicker: MatDatepicker<Moment>
    ) {
        this._date.setValue(normalizedMonthAndYear)
        this.momentSelected.emit(normalizedMonthAndYear.format('MM/DD/yyyy'))
        datepicker.close()
    }
}
