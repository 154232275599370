import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'
import { Observable, ReplaySubject } from 'rxjs'
import { PROD_API_URL } from '../../const/constants'
import { ISubcategory } from '../../interfaces/subcategory'

@Injectable({
    providedIn: 'root',
})
export class SubcategoriesService {
    private _subcategories = new ReplaySubject<ISubcategory[]>()
    get subcategories() {
        return this._subcategories
    }
    constructor(private httpService: HttpClient) {
        this.updateData()
    }
    updateData() {
        this.get().subscribe((categories) => {
            this._subcategories.next(categories)
        })
    }

    get(): Observable<ISubcategory[]> {
        return this.httpService.get<ISubcategory[]>(
            PROD_API_URL + 'api/subcategory'
        )
    }
    post(body: UntypedFormGroup): Observable<ISubcategory[]> {
        return this.httpService.post<ISubcategory[]>(
            PROD_API_URL + 'api/subcategory',
            body
        )
    }

    put(body: UntypedFormGroup, id: number): Observable<ISubcategory[]> {
        return this.httpService.put<ISubcategory[]>(
            PROD_API_URL + 'api/subcategory/' + id,
            body
        )
    }
    delete(id: number): Observable<ISubcategory[]> {
        return this.httpService.delete<ISubcategory[]>(
            PROD_API_URL + 'api/subcategory/' + id
        )
    }
}
