<mat-form-field appearance="fill" color="accent">
    <mat-label>{{ label }}</mat-label>
    <input
        matInput
        [matDatepicker]="dp"
        [formControl]="date"
        [min]="minValue"
        [max]="maxDate"
        [disabled]="disabled"
    />
    <mat-hint>MM/YYYY</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
    <mat-datepicker
        #dp
        startView="multi-year"
        (monthSelected)="getMoment($event, dp)"
        panelClass="example-month-picker"
    >
    </mat-datepicker>
</mat-form-field>
