//export const PROD_API_URL = 'https://localhost:44378/'
export const PROD_API_URL = 'https://ecclesia-be.azurewebsites.net/'
export const JWT_TOKEN = 'jwt_token'
export const COOKIE_ROLE = 'role'
export enum RecordTypes {
    'Ingreso' = 1,
    'Egreso' = 2,
}
export const RECEIPT_DATA = 'receiptData'
export const CONFIGURATION_ITEMS = {
    GeneralName: 'general_name',
    GeneralIcon: 'general_icon',
    FFIngresos: 'ff_ingresos',
    FFEgresos: 'ff_egresos',
    FFPettyCash: 'ff_pettyCash',
    FFReportes: 'ff_reportes',
    FFCalendar: 'ff_calendario',
    FFTicketDuplicateIngresos: 'ff_ticketDuplicate_ingresos',
    FFTicketDuplicateEgresos: 'ff_ticketDuplicate_egresos',
    TicketInfoPhone: 'ticketinfo_telefono',
    TicketInfoAddress: 'ticketinfo_direccion',
    TicketInfoFooter: 'ticketinfo_footer',
    TicketInfoAditionalHtml: 'ticketinfo_additionalHtml'
}
