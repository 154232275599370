import { Component, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
//import { SideNavService } from 'src/app/core/services/util/side-nav.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
    title = ''
    constructor(private router: Router) {
        this.router.events.subscribe((val: any) => {
            if (val instanceof NavigationEnd) {
                let url = val.url.split('/')
                this.title = capitalize(url[url.length - 1])
            }
        })
    }

    ngOnInit() {
        let url = this.router.url.split('/')
        this.title = capitalize(url[url.length - 1].replace('-', ' '))
    }
}
function capitalize(string: string) {
    return string.replace(/\w\S*/g, (w) =>
        w.replace(/^\w/, (c) => c.toUpperCase())
    )
}
