import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core'
import {
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { ICategory } from 'src/app/core/interfaces/Category'

@Component({
    selector: 'app-add-dialog',
    templateUrl: './add-dialog.component.html',
    styleUrls: ['./add-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AddDialogComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<AddDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ICategory
    ) {}
    isEdit = false
    formGroup!: UntypedFormGroup
    title = 'Nueva'
    formControl = new UntypedFormControl('', [Validators.required])

    ngOnInit(): void {
        if (this.data.name != null) {
            this.title = 'Editar'
            this.isEdit = true
        }
        console.log(this.data)
        this.formGroup = new UntypedFormGroup({
            name: new UntypedFormControl(this.data?.name, Validators.required),
            recordTypeId: new UntypedFormControl(
                this.data?.recordTypeId,
                Validators.required
            ),
        })
    }

    getErrorMessage() {
        return this.formControl.hasError('required') ? 'Campo requerido' : ''
    }

    onNoClick(): void {
        this.dialogRef.close()
    }

    submit() {
        console.log('submit')
    }
    public confirmAdd(): void {
        console.log(this.formGroup.value)
        this.dialogRef.close(this.formGroup.value)
    }
}
