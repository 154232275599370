import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core'
import {
    UntypedFormGroup,
    UntypedFormControl,
    Validators,
} from '@angular/forms'
import { ICategory } from 'src/app/core/interfaces/Category'
import { ISubcategory } from 'src/app/core/interfaces/subcategory'
import { CategoriesService } from 'src/app/core/services/api/categories.service'
import { AddSubcategoryComponent } from '../add-subcategory/add-subcategory.component'
import { ILocation } from 'src/app/core/interfaces/location'
import { LocationsService } from 'src/app/core/services/api/locations.service'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { DatePipe } from '@angular/common'
import { DateUtil } from '../../util/date-util'

@Component({
    selector: 'app-add-record',
    templateUrl: './add-record.component.html',
    styleUrls: ['./add-record.component.scss'],
})
export class AddRecordComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<AddSubcategoryComponent>,
        private categoryService: CategoriesService,
        private locationsService: LocationsService,
        private datePipe: DatePipe,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}
    today = new Date()
    private _isEdit = false
    private _isCalendar = false
    private _subcategories: ISubcategory[] = []
    private _locations: ILocation[] = []
    private _isEvent: boolean = false
    private _isDemo = false
    formGroup!: UntypedFormGroup
    title = 'Nuevo'
    formControl = new UntypedFormControl('', [Validators.required])
    get subcategories() {
        return this._subcategories
    }
    get locations() {
        return this._locations
    }
    get isEdit() {
        return this._isEdit
    }
    get isEvent() {
        return this._isEvent
    }
    get isCalendar() {
        return this._isCalendar
    }
    set isEvent(val: boolean) {
        this._isEvent = val
    }
    get isDemo() {
        return this._isDemo
    }
    ngOnInit(): void {
        const domain = window.location.host
        let tenant = domain.split('.')[0].replace('-', '')
        this._isDemo = tenant == 'demo'
        if (this.data.title != null) {
            this.title = this.data.title
        }
        this._isEdit = this.data?.isEdit
        this._isCalendar = this.data?.isCalendar ?? false
        if (this.data.categories != null) {
            this.data.categories = (this.data.categories as ICategory[]).sort(
                (a, b) => a.name.localeCompare(b.name)
            )
        }
        if (this.data.record != undefined) {
            this.categorySelected(this.data.record.categoryId)
        }
        this.formGroup = new UntypedFormGroup({
            AuthorizedRoles: new UntypedFormControl('naqarles'),
            ticketNo: new UntypedFormControl('-1'),
            categoryId: new UntypedFormControl(
                this.data?.record?.categoryId,
                Validators.required
            ),
            recordTypeId: new UntypedFormControl(this.data?.recordTypeId),
            subcategoryId: new UntypedFormControl(
                this.data?.record?.subcategoryId,
                Validators.required
            ),
            payee: new UntypedFormControl(
                this.data?.record?.payee,
                Validators.required
            ),
            amount: new UntypedFormControl(
                this.data?.record?.amount,
                Validators.required
            ),
            notes: new UntypedFormControl(
                this.data?.record?.notes,
                Validators.required
            ),
            locationId: new UntypedFormControl(this.data?.record?.locationId, Validators.required),
            dateCreated: new UntypedFormControl(new Date()),
        })
        this.locationsService.get().subscribe((locations) => {
            this._locations = locations
        })
    }

    getErrorMessage() {
        return this.formControl.hasError('required') ? 'Campo requerido' : ''
    }

    categorySelected(id: any) {
        this.categoryService.getById(id).subscribe((category) => {
            this._subcategories = category.subcategories.sort((a, b) =>
                a.name.localeCompare(b.name)
            )
        })
    }

    onNoClick(): void {
        this.dialogRef.close()
    }

    submit() {
        console.log('submit')
    }
    public confirmAdd(): void {
        console.log(this.formGroup.value)
        this.dialogRef.close(this.formGroup.value)
    }
}
