import { Injectable } from '@angular/core'
import { CanActivate, Router} from '@angular/router'
import { ConfigurationService } from '../api/configuration.service'
import { CONFIGURATION_ITEMS } from '../../const/constants'

@Injectable()
export class CalendarGuardService implements CanActivate {
    constructor(private configurationService: ConfigurationService, private router: Router){}
    canActivate(): boolean {
        const isCalendarEnabled = this.configurationService.retrieveByName(CONFIGURATION_ITEMS.FFCalendar)?.value == 'true'
        if (!isCalendarEnabled) {
            this.router.navigate(['ingresos'])
            return false
            
        }
        return true
    }
}
