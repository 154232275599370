import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, ReplaySubject, filter, firstValueFrom, map, shareReplay } from 'rxjs'
import { JWT_TOKEN, PROD_API_URL } from '../../const/constants'
import { IConfigurationService } from '../../interfaces/api/configuration-service'
import { ConfigurationItem } from '../../interfaces/configuration-item'
import { CookieService } from 'ngx-cookie-service'

const API_URL = 'api/configuration'

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService implements IConfigurationService {

    private _items: ConfigurationItem[] | undefined

    constructor(private httpClient: HttpClient, private cookieService: CookieService) {
        this.retrieve()
    }

    async retrieve(): Promise<ConfigurationItem[] | undefined> {
        let token = this.cookieService.get(JWT_TOKEN)
        if(!this._items && token.length > 0) {
            this._items = await firstValueFrom(this.httpClient.get<ConfigurationItem[]>(
                `${PROD_API_URL}${API_URL}`
            ))
        }
        return this._items
    }

    retrieveByName(name: string): ConfigurationItem | null {
        return this._items?.find((item) => item.name == name) ?? null
    }

    create(item: ConfigurationItem): Observable<ConfigurationItem> {
        return this.httpClient.post<ConfigurationItem>(`${PROD_API_URL}${API_URL}`, item)
    }

    update(id: number, item: ConfigurationItem): Observable<ConfigurationItem> {
        return this.httpClient.put<ConfigurationItem>(
            `${PROD_API_URL}${API_URL}/${id}`,
            item
        )
    }

    delete(id: number): Observable<ConfigurationItem> {
        return this.httpClient.delete<ConfigurationItem>(
            `${PROD_API_URL}${API_URL}/${id}`
        )
    }
}