import {
    ChangeDetectorRef,
    Component,
    Inject,
    OnInit,
    ViewEncapsulation,
} from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
    selector: 'app-confirmation',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.scss'],
    // encapsulation: ViewEncapsulation.None,
})
export class ConfirmationComponent {
    constructor(
        public dialogRef: MatDialogRef<ConfirmationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IConfirmationData
    ) {}

    onConfirm(): void {
        this.dialogRef.close(true)
    }

    onDismiss(): void {
        // Close the dialog, return false
        this.dialogRef.close(false)
    }
}

export interface IConfirmationData {
    title:string
    description: string
    okBtn?:string | null
    cancelBtn:string
}
export class ConfirmationData implements IConfirmationData{
    title= ""
    description = ""
    okBtn? = "Ok"
    cancelBtn = "Cancel"
    
}
