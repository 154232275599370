<div class="container">
    <h3 mat-dialog-title></h3>

    <div class="mat-dialog-content">
        <!--Contains mat-hint for characters count and has maxLengt set-->
        <div class="div">
            <p>¿Qué acción desea realizar?</p>
        </div>
        <div class="mat-dialog-actions">
            <button  mat-button (click)="response(EventAction.EDIT)">
                Editar Evento
            </button>
            <button mat-button (click)="response(EventAction.NEW)">
                Agregar nuevo Evento
            </button>
        </div>
    </div>
</div>
