import { Component, NgZone, OnInit, ViewEncapsulation } from '@angular/core'
import * as XLSX from 'xlsx'
import {
    startOfDay,
    endOfDay,
    endOfMonth,
    isSameDay,
    isSameMonth,
    addHours,
    startOfMonth,
    startOfWeek,
    endOfWeek,
} from 'date-fns'
import { Subject } from 'rxjs'
import {
    CalendarEvent,
    CalendarEventAction,
    CalendarEventTimesChangedEvent,
    CalendarView,
} from 'angular-calendar'
import { EventColor } from 'calendar-utils'
import { MatDialog } from '@angular/material/dialog'
import {
    EventDetailComponent,
    EventDetailResponse,
} from '../../components/dialogs/event-detail/event-detail.component'
import { RecordsService } from 'src/app/core/services/api/records.service'
import { IRecord } from 'src/app/core/interfaces/record'
import { DatePipe } from '@angular/common'
import { DateUtil } from '../../components/util/date-util'
import { AddRecordComponent } from '../../components/dialogs/add-record/add-record.component'
import { CategoriesService } from 'src/app/core/services/api/categories.service'
import { ICategory } from 'src/app/core/interfaces/Category'
import { LocationsService } from 'src/app/core/services/api/locations.service'
import { ILocation } from 'src/app/core/interfaces/location'
import { LoadingService } from 'src/app/core/services/util/loading.service'
import { EventsService } from 'src/app/core/services/api/events.service'
import { IEvent } from 'src/app/core/interfaces/event'
import { SnackbarService } from 'src/app/core/services/util/snackbar.service'
import { UntypedFormGroup } from '@angular/forms'
import {
    EventFilterComponent,
    IFilter,
} from '../../components/dialogs/event-filter/event-filter.component'
import { AddIncomeEventWizardComponent } from '../../components/dialogs/add-income-event-wizard/add-income-event-wizard.component'
import { ConfirmationComponent, ConfirmationData } from '../../components/dialogs/confirmation/confirmation.component'
import { EventAction, EventEditAddDialogComponent } from '../../components/dialogs/event-edit-add-dialog/event-edit-add-dialog.component'
import { ISubcategory } from 'src/app/core/interfaces/subcategory'
import { SubcategoriesService } from 'src/app/core/services/api/subcategories.service'

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CalendarComponent implements OnInit {
    private _events: IEvent[] = []
    private _printEvents: any[] = []
    private _categories_income: ICategory[] = []
    private _activeView = CalendarActiveView.Week
    private _locations: ILocation[] = []
    private _displayedColumns: string[] = ['date', 'time']
    private _eventsTable: ITableItem[] = []
    private _categories: ICategory[] = []
    private _subcategories: ISubcategory[] = []
    view: CalendarView = CalendarView.Week
    locale: string = 'es'
    CalendarView = CalendarView
    viewDate: Date = new Date()
    get printEventList() {
        return this._printEvents
    }
    get categories() {
        return this._categories
    }
    get displayedColumns() {
        return this._displayedColumns
    }
    get locations() {
        return this._locations
    }
    get eventsTable() {
        return this._eventsTable
    }
    get eventsData() {
        return this._events
    }
    actions: CalendarEventAction[] = [
        {
            label: '<i class="fas fa-fw fa-pencil-alt"></i>',
            a11yLabel: 'Edit',
            onClick: ({ event }: { event: CalendarEvent }): void => {
                this.handleEvent('Edited', event)
            },
        },
        {
            label: '<i class="fas fa-fw fa-trash-alt"></i>',
            a11yLabel: 'Delete',
            onClick: ({ event }: { event: CalendarEvent }): void => {
                this.events = this.events.filter((iEvent) => iEvent !== event)
                this.handleEvent('Deleted', event)
            },
        },
    ]

    refresh = new Subject<void>()

    events: CalendarEvent[] = []

    activeDayIsOpen: boolean = true

    constructor(
        private dialog: MatDialog,
        private recordsService: RecordsService,
        private eventsService: EventsService,
        private categoryService: CategoriesService,
        private subcategoriesService: SubcategoriesService,
        private locationService: LocationsService,
        private datePipe: DatePipe,
        private loadingService: LoadingService,
        private snackBarService: SnackbarService
    ) {}
    ngOnInit() {
        this.updateEvents(new Date())
        this.categoryService.categories_income.subscribe((categories) => {
            this._categories_income = categories
        })
        this.subcategoriesService.subcategories.subscribe((subcategories)=>{
            this._subcategories = subcategories
        })
        this.locationService.get().subscribe((locations) => {
            this._locations = locations
            locations.forEach((location) => {
                this._displayedColumns.push(location.locationId + '')
            })
        })
        this.categoryService.updateData()
    }
    /**
     * Updates events based on month
     * @param date month range for events
     */
    private updateEvents(date: Date) {
        let [startDate, endDate] = this.getStartAndEndDate(date)
        this.eventsService.getEvents(startDate!, endDate!).subscribe({
            next: (events) => {
                this._events = events
                this._printEvents = events
                this.events = events.map((record) => {
                    return new CalendarEventModel(
                        record.id ?? 0,
                        DateUtil.convertDateStringToDate(record.eventDateStart),
                        record.eventTimeStart,
                        DateUtil.convertDateStringToDate(record.eventDateEnd),
                        record.eventTimeEnd,
                        record.description,
                        this.getColor(1),
                        record.restricted
                    )
                })

                this.generateEvents()
            },
        })
        /*this.recordsService.eventsByRange(startDate!, endDate!).subscribe({
            next: (events) => {
                this._events = events
                this.saveCategories()
                this.events = events.map((record) => {
                    return new CalendarEventModel(
                        record.id ?? 0,
                        DateUtil.convertDateStringToDate(record.eventDateStart),
                        record.eventTimeStart,
                        DateUtil.convertDateStringToDate(record.eventDateEnd),
                        record.eventTimeEnd,
                        record.notes,
                        this.getColor(record.categoryId)
                    )
                })

                this.generateEvents()
            },
        })*/
    }
    private getStartAndEndDate(date: Date): [string, string] {
        var startDate = ''
        var endDate = ''
        switch (this._activeView) {
            case CalendarActiveView.Month:
                {
                    startDate =
                        this.datePipe.transform(
                            startOfMonth(date),
                            'MM/dd/yyyy'
                        ) ?? ''
                    endDate =
                        this.datePipe.transform(
                            endOfMonth(date),
                            'MM/dd/yyyy'
                        ) ?? ''
                }
                break
            case CalendarActiveView.Week:
                {
                    startDate =
                        this.datePipe.transform(
                            startOfWeek(date),
                            'MM/dd/yyyy'
                        ) ?? ''
                    endDate =
                        this.datePipe.transform(
                            endOfWeek(date),
                            'MM/dd/yyyy'
                        ) ?? ''
                }
                break
            case CalendarActiveView.Day:
                {
                    startDate =
                        this.datePipe.transform(
                            startOfDay(date),
                            'MM/dd/yyyy'
                        ) ?? ''
                    endDate =
                        this.datePipe.transform(endOfDay(date), 'MM/dd/yyyy') ??
                        ''
                }
                break
        }
        return [startDate, endDate]
    }
    private getColor(categoryId: number) {
        const index = this._categories.findIndex(
            (cat) => cat.categoryId == categoryId
        )
        return colors[index]
    }
    /*private saveCategories() {
        const catId = [
            ...new Set(this.__records.map((record) => record.categoryId)),
        ]
        const allCategories = this._categories_income.concat(
            this._categories_outcome
        )
        catId.forEach((category) => {
            const found = allCategories.find(
                (cat) => category == cat.categoryId
            )
            if (found != undefined) {
                this._categories.push(found)
            }
        })
        console.log(this._categories)
    }*/
    dayClicked({
        date,
        events,
    }: {
        date: Date
        events: CalendarEvent[]
    }): void {
        if (isSameMonth(date, this.viewDate)) {
            if (
                (isSameDay(this.viewDate, date) &&
                    this.activeDayIsOpen === true) ||
                events.length === 0
            ) {
                this.activeDayIsOpen = false
                this.addIncome()
            } else {
                this.activeDayIsOpen = true
            }
            this.viewDate = date
        }
    }

    eventTimesChanged({
        event,
        newStart,
        newEnd,
    }: CalendarEventTimesChangedEvent): void {
        this.events = this.events.map((iEvent) => {
            if (iEvent === event) {
                return {
                    ...event,
                    start: newStart,
                    end: newEnd,
                }
            }
            return iEvent
        })
        this.handleEvent('Dropped or resized', event)
    }

    handleEvent(action: string, event: CalendarEvent): void {
        
        const dialogRef = this.dialog.open(EventEditAddDialogComponent)
        dialogRef.afterClosed().subscribe((response)=>{
            if(response === EventAction.EDIT){
                this.editarEvento(event)
            } else if(response === EventAction.NEW){
                if(event.restricted){
                    const data: ConfirmationData ={
                        title:'',
                        description: 'La fecha elegida esta restringida por otro evento',
                        cancelBtn: 'Cerrar'
                    }
                    this.dialog.open(ConfirmationComponent,{
                        data:data
                    })
                    return
                }
                this.hourClicked({date: event.start})
            }
        })
        
    }
    private editarEvento(event: CalendarEvent){
        const eventData = this._events.find((record) => record.id == event.id)
        const dialogRef = this.dialog.open(EventDetailComponent, {
            data: eventData,
        })
        dialogRef.afterClosed().subscribe((response: EventDetailResponse) => {
            if (response === EventDetailResponse.SAVED || response === EventDetailResponse.DELETED) {
                var snackbarDetail = response === EventDetailResponse.SAVED ? "actualizó" : "eliminó"
                this.snackBarService.successSnackBar(
                    `Se ${snackbarDetail} el evento con éxito`
                )
                this.updateEvents(this.viewDate)
            } else if (response === EventDetailResponse.RECORD && eventData) {
                this.openRecord(eventData?.recordId)
            } else if (response === EventDetailResponse.CONFLICT) {
                this.snackBarService.errorSnackBar(
                    'La fecha seleccionada esta reservada para otro evento',
                    'Cerrar',
                    5000
                )
            } 
            else if(response === EventDetailResponse.ERROR){
                this.snackBarService.errorSnackBar(
                    'Hubo un problema al editar el evento',
                    'Cerrar',
                    5000
                )
            }
        })
        /*const categories =
            eventData?.recordTypeId == 1
                ? this._categories_income
                : this._categories_outcome*/
        /*if (eventData != undefined) {
            this.ngZone.run(() => {
                const dialogRef = this.dialog.open(AddRecordComponent, {
                    data: {
                        record: eventData,
                        categories: categories,
                        isCalendar: true,
                        isEdit: true,
                        title: 'Editar registro',
                        id: eventData.id,
                        recordTypeId: eventData.recordTypeId,
                    },
                })
                dialogRef.afterClosed().subscribe((result) => {
                    if (result && eventData.id != undefined) {
                        this.loadingService.show()
                        this.recordsService
                            .put(result, eventData.id)
                            .subscribe((response) => {
                                this.loadingService.hide()
                                this.updateEvents(new Date())
                            })
                    }
                })
            })
        }*/
    }
    private openRecord(id: number) {
        this.recordsService.getById(id).subscribe((record) => {
            this.openRecordDialog(record)
        })
    }
    openRecordDialog(record: IRecord) {
        const dialogRef = this.dialog.open(AddRecordComponent, {
            data: {
                record: record,
                categories: this._categories_income,
                isCalendar: true,
                isEdit: true,
                title: 'Editar registro',
                id: record.id,
                recordTypeId: record.recordTypeId,
            },
        })
        dialogRef.afterClosed().subscribe((response) => {
            if (response) {
                this.editRecord(response, record.id!!)
            }
        })
    }
    private editRecord(record: UntypedFormGroup, recordId: number) {
        this.loadingService.show()
        this.recordsService.put(record, recordId).subscribe((response) => {
            this.loadingService.hide()

            this.snackBarService.successSnackBar('Se guardó correctamente')
        })
    }
    addEvent(): void {
        this.events = [
            ...this.events,
            {
                title: 'New event',
                start: startOfDay(new Date()),
                end: endOfDay(new Date()),
                color: colors['red'],
                draggable: true,
                resizable: {
                    beforeStart: true,
                    afterEnd: true,
                },
                restricted: false
            },
        ]
    }

    deleteEvent(eventToDelete: CalendarEvent) {
        this.events = this.events.filter((event) => event !== eventToDelete)
    }

    setView(view: CalendarView, activeView: CalendarActiveView) {
        this._activeView = activeView
        this.updateEvents(this.viewDate)
        this.view = view
    }

    closeOpenMonthViewDay() {
        this.updateEvents(this.viewDate)

        this.activeDayIsOpen = false
    }
    printEvents() {
        const [startDate, endDate] = this.getStartAndEndDate(this.viewDate)
        const dialogRef = this.dialog.open(EventFilterComponent, {
            data: { startDate, endDate },
        })

        dialogRef.afterClosed().subscribe((response) => {
            if (response != null) {
                this.printFiltered(response)
            }
        })

        //window.print()
    }
    private printFiltered(filters: IFilter) {
        if (filters.start.length == 0 && filters.end.length == 0) {
            return
        }
        const start = this.datePipe.transform(filters.start, 'MM/dd/yyyy')
        const end = this.datePipe.transform(filters.end, 'MM/dd/yyyy')
        this.eventsService
            .getEvents(start!!, end!!)
            .subscribe((events: any[]) => {
                console.log(events)
                if (filters.category != -1) {
                    this._printEvents = events.filter((event) => {
                        return event.categoryId == filters.category
                    })
                } else {
                    this._printEvents = events
                }
                if (filters.subcategory.length > 0) {
                    this._printEvents = this._printEvents.filter(
                        (item) => {
                            return filters.subcategory.some(
                                (id) => item.subCategoryId == id
                            )
                        }
                        //(event) => event.categoryId == filters.category
                    )
                }
                if (filters.location.length > 0) {
                    this._printEvents = this._printEvents.filter((item) => {
                        return filters.location.some(
                            (location) => item.location == location
                        )
                    })
                }
                let locations =
                    filters.location.length > 0
                        ? filters.location
                        : this._locations.map((location) => location.name)
                this._generateExcelEvents(this._printEvents, locations)
               
                /*setTimeout(() => {
                window.print()
            }, 200)*/
            })
    }
    private getSubcategoryName(id: number):string{
        return this._subcategories.find(s=> s.subcategoryId == id)?.name ?? ''
    }
    private _generateExcelEvents(events: IEvent[], locations: string[]) {
        let grouped = this.groupByDate(events)
        let excelArr = []
        excelArr.push(['Fecha', 'Hora', 'Subcategoria',...locations])
        const keys = Object.keys(grouped)
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i]
            const event = grouped[key]
            event.forEach((value: any, index: number) => {
                const title = index == 0 ? key : ''
                let indexLocation = locations.indexOf(value.location) + 3
                let arr = [title, value.eventTimeStart, this.getSubcategoryName(value.subCategoryId)]
                const newArr = this.insertAtPosition(
                    arr,
                    value.description,
                    indexLocation,
                    ''
                )
                excelArr.push(newArr)
            })
        }
        const merges = this.getMergedCellsRanges(grouped, keys)

        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(excelArr)
        ws['!merges'] = merges

        const wb: XLSX.WorkBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'Eventos')
        XLSX.writeFile(wb, 'eventos.xlsx')
    }
    private insertAtPosition<T>(
        array: T[],
        element: T,
        position: number,
        defaultValue: T
    ): T[] {
        const newArray = Array(Math.max(position + 1, array.length + 1)).fill(
            defaultValue
        ) // Create a new array with sufficient length
        newArray[position] = element // Insert the element at the desired position
        for (let i = 0; i < array.length; i++) {
            newArray[i] = array[i] // Copy existing elements to the new array
        }
        return newArray
    }
    private getMergedCellsRanges(grouped: any, keys: string[]) {
        const merges = []
        let startIndex = 1
        for (let i = 0; i < keys.length; i++) {
            if (grouped[keys[i]].length > 1) {
                merges.push({
                    s: { r: startIndex, c: 0 },
                    e: { r: startIndex + grouped[keys[i]].length - 1, c: 0 },
                }) // Merge cells for the same date
                startIndex = i + grouped[keys[i]].length - 1
                continue
            }
            startIndex = startIndex + 1
        }
        return merges
    }
    private generateEvents() {
        const recordsGrouped = this.groupByDate(this._events)
        const table: ITableItem[] = []
        Object.keys(recordsGrouped).forEach((date) => {
            const groupedEvents = recordsGrouped[date]
            if (groupedEvents.length > 0) {
                const e = groupedEvents[0]
                var keyValue: { [key: string]: any } = {}
                keyValue[e.locationId!! + ''] = e.notes
                keyValue['time'] = e.eventTimeStart
                keyValue['date'] = e.eventDateStart
                table.push(keyValue)
            }
            if (groupedEvents.length > 1) {
                for (var i = 1; i < groupedEvents.length; i++) {
                    const e = groupedEvents[i]
                    var keyValue: { [key: string]: any } = {}
                    keyValue[e.locationId!! + ''] = e.notes
                    keyValue['time'] = e.eventTimeStart
                    keyValue['date'] = e.eventDateStart
                    table.push(keyValue)
                }
            }
        })

        this._eventsTable = table
    }
    hourClicked(event:any){
        
        let date = new Date(event.date)
        const dialogRef = this.dialog.open(AddIncomeEventWizardComponent, {
            width: '95%',
            maxHeight: '90vh',
            data: {
                title: 'Nuevo ingreso',
                recordTypeId: 1,
                categories: this._categories_income,
                isEdit: false,
                locations: this._locations,
                date:date
            },
        })
        dialogRef.afterClosed().subscribe(() => {
            this.updateEvents(this.viewDate)
        })
    }
    addIncome() {
        const dialogRef = this.dialog.open(AddIncomeEventWizardComponent, {
            width: '90%',
            data: {
                title: 'Nuevo ingreso',
                recordTypeId: 1,
                categories: this._categories_income,
                isEdit: false,
                locations: this._locations,
            },
        })
        dialogRef.afterClosed().subscribe(() => {
            this.updateEvents(this.viewDate)
        })
    }
    private groupByDate(data: any[]) {
        return data.reduce((groups, item) => {
            const { eventDateStart } = item

            if (!groups[eventDateStart]) {
                groups[eventDateStart] = []
            }

            groups[eventDateStart].push(item)

            return groups
        }, {})
    }
}

class CalendarEventModel implements CalendarEvent {
    id?: string | number | undefined
    start: Date
    end?: Date | undefined
    title: string
    color?: EventColor | undefined
    actions?: CalendarEventAction[] | undefined
    allDay?: boolean | undefined
    cssClass?: string | undefined
    resizable?:
        | { beforeStart?: boolean | undefined; afterEnd?: boolean | undefined }
        | undefined
    draggable?: boolean | undefined
    meta?: any
    restricted: boolean
    constructor(
        id: number,
        startDate: Date,
        startTime: string,
        endDate: Date,
        endTime: string,
        title: string,
        color: EventColor,
        restricted: boolean
    ) {
        this.id = id
        this.start = DateUtil.AddTimeToDate(startDate, startTime)
        this.end = DateUtil.AddTimeToDate(endDate, endTime)
        this.color = color
        this.title = title
        this.restricted = restricted
    }
    
}
enum CalendarActiveView {
    Month,
    Week,
    Day,
}
interface ITableItem {
    [key: string]: any
}

const colors: Record<string, EventColor> = {
    0: {
        primary: '#ff831e',
        secondary: '#ff831e',
    },
    1: {
        primary: '#83e710',
        secondary: '#83e710',
    },
    2: {
        primary: '#2015b3',
        secondary: '#2015b3',
    },
    3: {
        primary: '#d414a4',
        secondary: '#d414a4',
    },
    4: {
        primary: '#d1c40c',
        secondary: '#d1c40c',
    },
    5: {
        primary: '#0aacdd',
        secondary: '#0aacdd',
    },
    default: {
        primary: '#1e90ff',
        secondary: '#D1E8FF',
    },
}
