import { DatePipe } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { MAT_DATE_LOCALE } from '@angular/material/core'
import { MatDatepickerInputEvent } from '@angular/material/datepicker'
import { MatTableDataSource } from '@angular/material/table'
import { IDailyBalance } from 'src/app/core/interfaces/daily-balance'
import { IRecord } from 'src/app/core/interfaces/record'
import { CashFlowService } from 'src/app/core/services/api/cash-flow.service'
import { GeneralBalanceService } from 'src/app/core/services/api/general-balance.service'
import { DailyBalanceReportService } from 'src/app/core/services/api/reports.service'

@Component({
    selector: 'app-daily-cut-restricted',
    templateUrl: './daily-cut-restricted.component.html',
    styleUrls: ['./daily-cut-restricted.component.scss'],
})
export class DailyCutRestrictedComponent implements OnInit {
    dataSourceIncome = new MatTableDataSource<IDailyCutTableData>()
    dataSourceOutcome = new MatTableDataSource<IDailyCutTableData>()
    dataSourceTotal = new MatTableDataSource<ISummaryDataTable>()
    dataSourceGlobal = new MatTableDataSource<ISummaryDataTable>()
    displayedColumns = ['ticket', 'category', 'description', 'amount', 'total']
    title = ''
    private _maxDate = new Date()
    private _currentMonthBalance = 0
    private _yesterdayBalance = 0
    private _income = 0
    private _outcome = 0
    private _date = new Date()
    get income() {
        return this._income
    }
    get outcome() {
        return this._outcome
    }
    get date() {
        return this._date
    }
    get maxDate() {
        return this._maxDate
    }
    get previousBalance() {
        return this._yesterdayBalance
    }

    set date(date: Date) {
        this._date = date
    }
    constructor(
        private dailyBalanceService: DailyBalanceReportService,
        private cashFlowService: CashFlowService,
        private datePipe: DatePipe
    ) {}

    ngOnInit(): void {
        this.updateTitle()
        this.getPreviousBalance()
    }
    getDailyCut() {
        this._income = 0
        this._outcome = 0
        let dateString = this.datePipe.transform(this.date, 'MM/dd/yyyy')
        if (dateString != null) {
            this.dailyBalanceService.get(dateString).subscribe((results) => {
                this.convertToDataSource2(results)
            })
        }
    }
    private getPreviousBalance() {
        let yesterday = new Date(this.date)
        yesterday.setDate(yesterday.getDate() - 1)
        let day = yesterday.getDate() - 1
        let month = (yesterday.getMonth() + 1).toString()
        let year = yesterday.getFullYear().toString()

        this.cashFlowService.get(month, year).subscribe((response) => {
            if (response !== null) {
                const arr = Object.values(response.Data.Data)
                this._yesterdayBalance = (arr[day] as any).Saldo
                this.getDailyCut()
            }
        })
    }
    private updateTitle() {
        this.title =
            'Corte diario  ' +
                this.datePipe.transform(this.date, 'dd/MM/yyyy') ?? ''
    }
    convertToDataSource2(results: IDailyBalance[]) {
        this.dataSourceIncome.data = []
        this.dataSourceOutcome.data = []
        results.forEach((result) => {
            let type = result.Key
            if (type === 'Ingresos') {
                this._income = result.Total
            } else {
                this._outcome = result.Total
            }
            let table: IDailyCutTableData[] = []
            result.Categorias.forEach((category) => {
                table.push({
                    title: '',
                    category: category.Key,
                    amount: null,
                    style: 'category',
                })
                category.Subcategorias.forEach((subcategory) => {
                    let sub = subcategory.Key
                    subcategory.Registros.forEach((registry) => {
                        table.push({
                            title: registry.Descripcion,
                            category: sub,
                            amount: registry.Cantidad,
                            ticket: registry.Recibo,
                            style: '',
                        })
                    })
                })
                table.push({
                    title: '',
                    category: 'Total ' + category.Key,
                    amount: null,
                    total: category.Total,
                    style: 'category bottom',
                })
                table.push({
                    title: '',
                    category: '',
                    amount: null,
                    style: '',
                })
                if (type === 'Ingresos') {
                    this.dataSourceIncome.data = table
                } else {
                    this.dataSourceOutcome.data = table
                }
            })
        })
        let newTotal: ISummaryDataTable[] = []
        newTotal.push({
            title: 'Ingresos',
            quantity: this._income,
            style: 'label-ingreso',
        })
        newTotal.push({
            title: 'Egresos',
            quantity: this._outcome,
            style: 'label-egreso',
        })
        newTotal.push({
            title: 'Saldo corte diario',
            quantity: this._income - this._outcome,
            style: 'category',
        })
        this.dataSourceTotal.data = newTotal

        newTotal = []
        let balanceToday = this._income - this._outcome

        newTotal.push({
            title: 'Saldo día anterior',
            quantity: this._yesterdayBalance,
            style:
                this._yesterdayBalance > 0 ? 'label-ingreso' : 'label-egreso',
        })
        newTotal.push({
            title: 'Saldo corte del día',
            quantity: balanceToday,
            style:
                this._income - this._outcome < 0
                    ? 'label-egreso'
                    : 'label-ingreso',
        })
        newTotal.push({
            title: 'Saldo global final',
            quantity: balanceToday + this._yesterdayBalance,
            style: 'category',
        })
        this.dataSourceGlobal.data = newTotal
    }
    sum() {
        return this.income - this.outcome
    }
    print() {
        window.print()
    }
    dateChanged(event: MatDatepickerInputEvent<Date>) {
        this.updateTitle()
        this.getPreviousBalance()
    }
}
interface IDailyCutTableData {
    title: string
    category: string
    ticket?: number | undefined
    amount?: number | null
    total?: number | null
    style: string
}

interface ISummaryDataTable {
    title: string
    quantity: number
    style: string
}
