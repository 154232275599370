import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class LoadingService {
    private _loading = new BehaviorSubject<boolean>(false)
    get loading() {
        return this._loading.asObservable()
    }
    constructor() {}

    show() {
        this._loading.next(true)
    }
    hide() {
        this._loading.next(false)
    }
}
