<nav class="navbar">
    <app-side-nav-toggler></app-side-nav-toggler>
    <div class="logout" (click)="closeSession()">
        <p>Cerrar Sesión</p>
        <mat-icon>logout</mat-icon>
    </div>
    <!--<div class="user">
        <p [matMenuTriggerFor]="menu">{{ user }}</p>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="closeSession()">
                Cerrar Sesión
            </button>
        </mat-menu>
    </div>-->
</nav>
