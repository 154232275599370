<div class="container material-dialog-container">
    <h3 mat-dialog-title>{{ title }} categoria</h3>

    <form
        autocomplete="off"
        class="mat-dialog-content"
        (submit)="(submit)"
        #formControl="ngForm"
        [formGroup]="formGroup"
    >
        <!--Contains mat-hint for characters count and has maxLengt set-->
        <div class="form" [formGroup]="formGroup">
            <mat-form-field color="accent">
                <mat-label>Nombre</mat-label>
                <input
                    matInput
                    #inputstate
                    [(ngModel)]="data.name"
                    formControlName="name"
                />
                <mat-error *ngIf="formGroup.invalid">{{
                    getErrorMessage()
                }}</mat-error>
            </mat-form-field>
        </div>

        <mat-form-field *ngIf="!isEdit" color="accent">
            <mat-label>Tipo</mat-label>
            <mat-select formControlName="recordTypeId">
                <mat-option value="1"> Ingresos </mat-option>
                <mat-option value="2"> Egresos </mat-option>
            </mat-select>
        </mat-form-field>

        <div mat-dialog-actions>
            <button
                mat-button
                [disabled]="!formGroup.valid"
                [mat-dialog-close]="1"
                (click)="confirmAdd()"
            >
                Guardar
            </button>
            <button
                type="button"
                mat-button
                (click)="onNoClick()"
                tabindex="-1"
            >
                Cancelar
            </button>
        </div>
    </form>
</div>
