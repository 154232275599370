import {
    STEPPER_GLOBAL_OPTIONS,
    StepperSelectionEvent,
} from '@angular/cdk/stepper'
import { DatePipe } from '@angular/common'
import { AfterViewInit, Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { ISubcategory } from 'src/app/core/interfaces/subcategory'
import { CategoriesService } from 'src/app/core/services/api/categories.service'
import { EventsComponent, IEventData } from '../../events/events.component'
import { RecordsService } from 'src/app/core/services/api/records.service'
import { IRecord } from 'src/app/core/interfaces/record'
import { IEvent } from 'src/app/core/interfaces/event'
import { DateUtil } from '../../util/date-util'

@Component({
    selector: 'app-add-income-event-wizard',
    templateUrl: './add-income-event-wizard.component.html',
    styleUrls: ['./add-income-event-wizard.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { showError: true },
        },
    ],
})
export class AddIncomeEventWizardComponent implements OnInit, AfterViewInit {
    private _firstFormGroup!: FormGroup
    private _title = 'Nuevo'
    private _isEdit = false
    private _subcategories: ISubcategory[] = []
    private _isCalendar = false
    private _loading = false
    private _record!: IRecord
    private _default_event_date!:Date 
    private _canSave = false
    private _eventData: IEventData = {
        recordId: 0,
        recordTitle: '',
    }

    get firstFormGroup() {
        return this._firstFormGroup
    }
    get secondFormGroup() {
        return this._firstFormGroup
    }
    get title() {
        return this._title
    }
    get isEdit() {
        return this._isEdit
    }

    get isCalendar() {
        return this._isCalendar
    }
    get subcategories() {
        return this._subcategories
    }
    get eventData() {
        return this._eventData
    }
    get loading() {
        return this._loading
    }
    get canSave(){
        return this._canSave
    }
    @ViewChild(EventsComponent) eventsComponent!:EventsComponent
    constructor(
        public dialogRef: MatDialogRef<AddIncomeEventWizardComponent>,
        private categoryService: CategoriesService,
        private recordService: RecordsService,
        private datePipe: DatePipe,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}
    ngAfterViewInit(): void {
        this._canSave = true
    }

    ngOnInit(): void {
        if (this.data.title != null) {
            this._title = this.data.title
        }

        this._isCalendar = this.data?.isCalendar ?? false
        if (this.data.record != undefined) {
            this.categorySelected(this.data.record.categoryId)
            this._record = this.data.record
        }
        if(this.data.date){
            this._default_event_date = this.data.date
        }
        this._firstFormGroup = new FormGroup({
            AuthorizedRoles: new FormControl('naqarles'),
            ticketNo: new FormControl('-1'),
            categoryId: new FormControl(
                this.data?.record?.categoryId,
                Validators.required
            ),
            recordTypeId: new FormControl(this.data?.recordTypeId),
            subcategoryId: new FormControl(
                this.data?.record?.subcategoryId,
                Validators.required
            ),
            payee: new FormControl(
                this.data?.record?.payee,
                Validators.required
            ),
            amount: new FormControl(
                this.data?.record?.amount,
                Validators.required
            ),
            notes: new FormControl(
                this.data?.record?.notes,
                Validators.required
            ),
            locationId: new FormControl(this.data?.record?.locationId, Validators.required),
            dateCreated: new FormControl(new Date()),
        })
    }

    getErrorMessage() {
        return 'Campo requerido'
    }

    categorySelected(id: any) {
        this.categoryService.getById(id).subscribe((category) => {
            this._subcategories = category.subcategories.sort((a, b) =>
                a.name.localeCompare(b.name)
            )
        })
    }
    onStepChange(event: StepperSelectionEvent) {
        switch (event.selectedIndex) {
            case 1: {
                this._loading = true
                this._saveIncome()
            }
        }
    }
    saveEvents(){
        this.eventsComponent.saveAll()
        this._canSave = false
    }
    close() {
        this.dialogRef.close()
    }
    private _saveIncome() {
        if (this._record == undefined) {
            this.recordService
                .post(this.firstFormGroup.value)
                .subscribe((result) => {
                    this._loading = false
                    this._record = result
                    let event!:IEvent
                    if(this._default_event_date){
                        const thirtyMinutesLater = new Date(this._default_event_date)
                        thirtyMinutesLater.setMinutes(thirtyMinutesLater.getMinutes() + 30)
                        event = {
                            recordId:this._record.id ?? 0, 
                            description: this._record.notes,
                            eventDateStart: this.datePipe.transform(this._default_event_date, 'yyyy-MM-dd') ??'', 
                            eventTimeStart: DateUtil.convertTimeTo24HourFormat(
                                this.datePipe.transform(this._default_event_date, 'HH:mm') ?? '00:00:00'
                            ),
                            eventDateEnd:this.datePipe.transform(this._default_event_date, 'yyyy-MM-dd') ??'',
                            eventTimeEnd: DateUtil.convertTimeTo24HourFormat(
                                this.datePipe.transform(thirtyMinutesLater, 'HH:mm') ?? '00:00:00'
                            ),
                            restricted: false 
                        }
                    }
                    this._eventData = {
                        recordId: this._record.id ?? 0,
                        recordTitle: this._record.notes,
                        defaultEvent: event
                    }
                })
        } else {
            this.recordService
                .put(this._firstFormGroup.value, this._record.id!)
                .subscribe((result) => {
                    this._loading = false
                    this._eventData = {
                        recordId: this._record.id ?? 0,
                        recordTitle: this.firstFormGroup.get('notes')?.value,
                    }
                })
        }
    }
}
