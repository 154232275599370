<div class="container event-filters">
    <h3 mat-dialog-title>Filtrar Eventos</h3>

    <div class="mat-dialog-content">
        <div class="filters">
            <div class="filter-row">
                <mat-form-field floatPlaceholder="never" color="accent">
                    <mat-label>Categoria</mat-label>
                    <mat-select
                        (selectionChange)="categoryChanged()"
                        [(value)]="selectedCategory"
                    >
                        <mat-option value="-1">Sin Filtro</mat-option>
                        <mat-option
                            *ngFor="let category of categories"
                            [value]="category.categoryId"
                        >
                            {{ category.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field floatPlaceholder="never" color="accent">
                    <mat-label>Subcategoria</mat-label>
                    <mat-select [(value)]="selectedSubcategory" multiple>
                        <mat-option value="-1">Sin Filtro</mat-option>
                        <mat-option
                            *ngFor="let subcategory of subcategories"
                            [value]="subcategory.subcategoryId"
                        >
                            {{ subcategory.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="filter-row">
                <mat-form-field floatPlaceholder="never" color="accent">
                    <mat-label>Ubicacion</mat-label>
                    <mat-select [(value)]="selectedLocation" multiple>
                        <mat-option value="-1">Sin Filtro</mat-option>
                        <mat-option
                            *ngFor="let location of locations"
                            [value]="location.name"
                        >
                            {{ location.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="filter-row">
                <mat-form-field>
                    <mat-label>Rango de fechas</mat-label>
                    <mat-date-range-input
                        [formGroup]="range"
                        [rangePicker]="picker"
                    >
                        <input
                            matStartDate
                            formControlName="start"
                            placeholder="Fecha Inicial"
                        />
                        <input
                            matEndDate
                            formControlName="end"
                            placeholder="Fecha Final"
                        />
                    </mat-date-range-input>
                    <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
                    <mat-datepicker-toggle
                        matSuffix
                        [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                    <!--<mat-error *ngIf="range.controls['start']hasError('matStartDateInvalid')">Invalid start date</mat-error>
                        <mat-error *ngIf="range.controls['end']hasError('matEndDateInvalid')">Invalid end date</mat-error>-->
                </mat-form-field>
                <button
                    (click)="resetRange()"
                    class="btn-clear"
                    mat-raised-button
                >
                    Limpiar fechas
                </button>
            </div>
        </div>

        <div class="mat-dialog-actions">
            <button (click)="download()" mat-button>Descargar</button>
            <button mat-dialog-close mat-button>Cancelar</button>
        </div>
    </div>
</div>
