<div class="toggler">
    <mat-icon class="icon" [ngClass]="{ hidden: sideNavService.hideSideNav }"
        >arrow_left</mat-icon
    >
    <button
        type="button"
        class="navbar-toggler"
        (click)="sideNavService.toggleSideNav()"
    >
        <mat-icon>menu</mat-icon>
    </button>
    <mat-icon class="icon" [ngClass]="{ hidden: !sideNavService.hideSideNav }"
        >arrow_right</mat-icon
    >
</div>
