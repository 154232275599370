<app-navbar></app-navbar>
<div class="wrapper">
    <app-side-nav></app-side-nav>
    <div class="content container">
        <app-page-header
            icon="home"
            [title]="title.length > 0 ? title : 'Inicio'"
        ></app-page-header>
        <router-outlet></router-outlet>
    </div>
</div>
