<div class="container material-dialog-container">
    <h3 mat-dialog-title>{{ title }} ubicación</h3>

    <form
        autocomplete="off"
        class="mat-dialog-content"
        (submit)="(submit)"
        #formControl="ngForm"
        [formGroup]="formGroup"
    >
        <!--Contains mat-hint for characters count and has maxLengt set-->
        <div class="form form-location" [formGroup]="formGroup">
            <mat-form-field color="accent">
                <mat-label>Nombre</mat-label>

                <input
                    matInput
                    #inputstate
                    [(ngModel)]="data.name"
                    formControlName="name"
                />
                <mat-error *ngIf="formGroup.invalid">{{
                    getErrorMessage()
                }}</mat-error>
            </mat-form-field>
            <mat-form-field color="accent">
                <mat-label>Abreviatura</mat-label>
                <input
                    matInput
                    #inputstate
                    [(ngModel)]="data.shortName"
                    formControlName="shortName"
                />
                <mat-hint>4 - 6 caracteres</mat-hint>
                <mat-error *ngIf="formGroup.invalid"
                    >Debe contener entre 4 y 6 caracteres</mat-error
                >
            </mat-form-field>

            <mat-form-field color="accent">
                <mat-label>Dirección</mat-label>
                <input
                    matInput
                    #inputstate
                    [(ngModel)]="data.address"
                    formControlName="address"
                />
            </mat-form-field>
        </div>
        <div mat-dialog-actions>
            <button
                mat-button
                [disabled]="!formGroup.valid"
                [mat-dialog-close]="1"
                (click)="confirmAdd()"
            >
                Guardar
            </button>
            <button
                type="button"
                mat-button
                (click)="onNoClick()"
                tabindex="-1"
            >
                Cancelar
            </button>
        </div>
    </form>
</div>
