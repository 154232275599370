import { Component, Inject, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { ICategory } from 'src/app/core/interfaces/Category'
import { ISubcategory } from 'src/app/core/interfaces/subcategory'
import { CategoriesService } from 'src/app/core/services/api/categories.service'
import { SubcategoriesService } from 'src/app/core/services/api/subcategories.service'
import { DateUtil } from '../../util/date-util'
import { LocationsService } from 'src/app/core/services/api/locations.service'
import { ILocation } from 'src/app/core/interfaces/location'

@Component({
    selector: 'app-event-filter',
    templateUrl: './event-filter.component.html',
    styleUrls: ['./event-filter.component.scss'],
})
export class EventFilterComponent implements OnInit {
    private _categories: ICategory[] = []
    private _allSubcategories: ISubcategory[] = []
    private _subcategories: ISubcategory[] = []
    private _locations: ILocation[] = []
    private _selectedCategory = -1
    private _selectedSubcategory: number[] = []
    private _selectedLocation: string[] = []
    range = new UntypedFormGroup({
        start: new UntypedFormControl(null),
        end: new UntypedFormControl(null),
    })
    get selectedCategory() {
        return this._selectedCategory
    }
    set selectedCategory(value: number) {
        this._selectedCategory = value
    }
    get selectedSubcategory() {
        return this._selectedSubcategory
    }
    set selectedSubcategory(value: number[]) {
        this._selectedSubcategory = value
    }
    get selectedLocation() {
        return this._selectedLocation
    }
    set selectedLocation(value: string[]) {
        this._selectedLocation = value
    }
    get categories() {
        return this._categories
    }
    get subcategories() {
        return this._subcategories
    }
    get locations() {
        return this._locations
    }
    constructor(
        public dialogRef: MatDialogRef<EventFilterComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: { startDate: string; endDate: string },
        private categoriesService: CategoriesService,
        private subcategoriesService: SubcategoriesService,
        private locationService: LocationsService
    ) {}

    ngOnInit(): void {
        this.categoriesService.categories_income.subscribe((categories) => {
            this._categories = categories
        })
        this.subcategoriesService.subcategories.subscribe((subcategories) => {
            this._allSubcategories = subcategories
        })
        this.locationService.get().subscribe((locations) => {
            this._locations = locations
        })
        this.range
            .get('start')
            ?.patchValue(
                DateUtil.convertDateStringToDateDMY(this.data.startDate, '/')
            )
        this.range
            .get('end')
            ?.patchValue(
                DateUtil.convertDateStringToDateDMY(this.data.endDate, '/')
            )
        console.log(this.range)
    }

    categoryChanged() {
        if (this.selectedCategory == -1) {
            this._subcategories = this._allSubcategories
            return
        }
        this.categoriesService
            .getById(this.selectedCategory)
            .subscribe((category) => {
                this._subcategories = category.subcategories.sort((a, b) =>
                    a.name.localeCompare(b.name)
                )
            })
    }
    resetRange() {
        this.range?.get('start')?.patchValue('')
        this.range?.get('end')?.patchValue('')
    }
    download() {
        const filters: IFilter = {
            category: this._selectedCategory,
            subcategory: this.selectedSubcategory,
            start: this.range.get('start')?.value,
            end: this.range.get('end')?.value,
            location: this.selectedLocation,
        }
        this.dialogRef.close(filters)
    }
}
export interface IFilter {
    category: number
    subcategory: number[]
    location: string[]
    start: string
    end: string
}
