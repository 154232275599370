import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'
import { Observable } from 'rxjs'
import { PROD_API_URL } from '../../const/constants'
import { IPetty } from '../../interfaces/petty'

@Injectable({
    providedIn: 'root',
})
export class PettyService {
    constructor(private httpService: HttpClient) {}

    get(): Observable<IPetty[]> {
        return this.httpService.get<IPetty[]>(
            PROD_API_URL + '/api/pettycashrecords'
        )
    }

    post(body: UntypedFormGroup): Observable<IPetty> {
        return this.httpService.post<IPetty>(
            PROD_API_URL + '/api/pettycashrecords',
            body
        )
    }
}
